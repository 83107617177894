export default function hasError<T>(errors: T): boolean {
    return Object.values(errors).some((error) => {
      if (Array.isArray(error)) {
        return error.some((err: T) => hasError(err));
      }
      return error !== undefined;
    });
  }

  export const bindActionToPromise = (dispatch: any, actionCreator: any) => (payload: any) => {
    return new Promise((resolve, reject) => dispatch(actionCreator(payload, { resolve, reject })));
  };