import { myProfileTypes } from "../../ActionTypes/authTypes";
import { MyProfileActions, MyProfileState} from "../../types/auth";

const initialState: MyProfileState = {
  pending: false,
  myProfileInfo: null,
  status: null,
  error: null
};

export default (state = initialState, action: MyProfileActions) => {
  switch (action.type) {
    case myProfileTypes.GET_MY_PROFILE_REQUEST:
      return {
        ...state,
        pending: true
      };
    case myProfileTypes.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        myProfileInfo: action.payload.myProfileInfo,
        status: action.payload.status,
        error: null
      };
    case myProfileTypes.GET_MY_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
