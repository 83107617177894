import { codeTypes } from "../../ActionTypes/initialTypes";
import {
  FetchCodeRequest,
  FetchCodeSuccess,
  FetchCodeSuccessPayload,
} from "../../types/types";

export const fetchCodeRequest = (code: string): FetchCodeRequest => ({
  type: codeTypes.FETCH_CODE_REQUEST,
  payload: { code: code },
});

export const fetchCodeSuccess = (
  payload: FetchCodeSuccessPayload
): FetchCodeSuccess => ({
  type: codeTypes.FETCH_CODE_SUCCESS,
  payload,
});
