import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers/rootReducer";
import { rootSaga } from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const saveToLocalStorage = (state: any) => {
  try {
    const updatedState = {
      config: state.config,
      ui: state.ui,
      category: state.category,
      smLink: state.smLink,
      language: state.language,
      code: state.code,
    }
    localStorage.setItem("state", JSON.stringify(updatedState));
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem("state");
    if(stateStr){
      const stateValue = JSON.parse(stateStr)
      let updatedState = {
        config: stateValue.config,
        ui: stateValue.ui,
        category: stateValue.category,
        smLink: stateValue.smLink,
        language: stateValue.language,
        code: stateValue.code,
      }
      return updatedState
    }else{
      return undefined
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedStore,
  applyMiddleware(sagaMiddleware)
);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

sagaMiddleware.run(rootSaga);

export default store;
