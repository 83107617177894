import { registerTypes } from "../../ActionTypes/authTypes";
import { RegisterActions, RegisterState} from "../../types/auth";

const initialState: RegisterState = {
  pending: false,
  data: null,
  status: null,
  error: null
};

export default (state = initialState, action: RegisterActions) => {
  switch (action.type) {
    case registerTypes.POST_REGISTER_REQUEST:
      return {
        ...state,
        pending: true
      };
    case registerTypes.POST_REGISTER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.data,
        status: action.payload.status,
        error: null
      };
    case registerTypes.POST_REGISTER_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
