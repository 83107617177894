import { getBookingHistory } from "api";

import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CommonLayout from "./CommonLayout";

const AccountSavelists = () => {
  const intl = useIntl();
  const [bookingHistory, setBookingHistory] = useState([]);

  useEffect(() => {
    getBookingHistory()
      .then((res) => {
        setBookingHistory(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8 ">
        <div>
          <h2 className="text-3xl font-semibold">{intl.formatMessage({ id: `Booking-History` })}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="ml-4 font-semibold flex-grow grid grid-cols-5 gap-4 overflow-hidden relative items-center p-3 sm:p-6 bg-white dark:bg-neutral-800">
          <div> {intl.formatMessage({ id: `Booking-No` })}</div>
          <div> {intl.formatMessage({ id: `Full-name` })}</div>
          <div> {intl.formatMessage({ id: `Address` })}</div>
          <div> {intl.formatMessage({ id: `Status` })}</div>
          <div> {intl.formatMessage({ id: `Total-Amount` })}</div>
        </div>
        {bookingHistory.map((bookingHistoryItem) => (
          <div className="ml-4 text-sm text-neutral-500 dark:text-neutral-400 flex-grow grid grid-cols-5 gap-4  overflow-hidden relative items-center p-3 sm:p-6 bg-white dark:bg-neutral-800">
            <div>{bookingHistoryItem.b_no}</div>
            <div>
              {bookingHistoryItem.first_name} {bookingHistoryItem.last_name}
            </div>
            <div style={{wordBreak: 'break-word'}}>{bookingHistoryItem.address1}</div>
            <div className={bookingHistoryItem.status === "SUCCESS" ? 'text-[#007f00]': 'text-[#EED202]'}>{bookingHistoryItem.status}</div>
            <div> {bookingHistoryItem.total_amount} {intl.formatMessage({ id: "SAR" })} </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
