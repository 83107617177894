import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import { Ar } from "types/initial";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

export interface GalleryImgs {
  id: number;
  title: string;
  url: string;
}
export interface GallerySliderProps {
  className?: string;
  galleryImgs: GalleryImgs[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  uiData: Ar;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
  uiData,
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();

  const { config } = useSelector((state: RootState) => {
    return state.config;
  });

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5 banner-dots "
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden slider-ltr`}>
        <div className="glide__track" data-glide-el="track">
        
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => (
              <div
                className={`nc-SectionHero3 relative ${className}`}
                data-nc-id="SectionHero3"
                key={index}
              >
                <div className="absolute z-10 p-3 inset-x-0 top-[3%] sm:top-[10%] text-center bg-white bg-opacity-25 flex flex-col items-center mx-auto space-y-3 lg:space-y-3 xl:space-y-3 banner-slider-new">
                  
                  <span className="font-medium text-4xl md:text-4xl xl:text-4xl !leading-[114%] text-neutral-900">
                    {item.title}
                  </span>
                  {/* <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
                    {uiData.popular_subtitle}
                  </span> */}
                </div>
                <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
                  <img
                    className="absolute inset-0 rounded-xl"
                    src={item.url}
                    alt="hero"
                  />
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900 "></div>
        {renderDots()}

        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between ">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return <>{renderSliderGallery()}</>;
};

export default GallerySlider;
