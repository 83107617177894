import { catDataTypes } from "../../ActionTypes/initialTypes";
import { CatDataActions, CatDataState} from "../../types/types";

const initialState: CatDataState = {
  pending: false,
  category: null,
  error: null
};

export default (state = initialState, action: CatDataActions) => {
  switch (action.type) {
    case catDataTypes.FETCH_CAT_DATA_REQUEST:
      return {
        ...state,
        pending: true
      };
    case catDataTypes.FETCH_CAT_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        category: action.payload.category,
        error: null
      };
    case catDataTypes.FETCH_CAT_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        category: null,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
