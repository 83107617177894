import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { GlobeAltIcon } from "@heroicons/react/outline";
import { FC, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { fetchLanguageRequest } from "redux/actions/initial/getLanguageActions";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const LoginButton = () => {
    const history = useHistory()
    const intl = useIntl()
  return (
    <div onClick={()=> history.push('/login')} className="Login-Btn text-base font-medium bg-primary-700 text-white p-2 my-2 rounded-none hover:cursor-pointer hover:text-primary-700 hover:bg-white border-primary-700 border-solid border-2 hover:dark:bg-neutral-900 " style={{whiteSpace: 'nowrap'}}>
      <AccountCircleRoundedIcon /> {" "} {intl.formatMessage({ id: "LOGIN-OR-SIGN-UP" })}
    </div>
  );
};
export default LoginButton;
