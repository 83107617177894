import { uiDataTypes } from "../../ActionTypes/initialTypes";
import { UiDataActions , UiDataState} from "../../types/types";

const initialState: UiDataState = {
  pending: false,
  ui: null,
  error: null
};

export default (state = initialState, action: UiDataActions) => {
  switch (action.type) {
    case uiDataTypes.FETCH_UI_DATA_REQUEST:
      return {
        ...state,
        pending: true
      };
    case uiDataTypes.FETCH_UI_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        ui: action.payload.ui,
        error: null
      };
    case uiDataTypes.FETCH_UI_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        ui: null,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
