import React, { FC, useEffect, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { CategoryData, ConfigData } from "types/initial";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import SectionGridFeaturePlacesList from "./SectionGridFeaturePlacesList";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import StayDatesRangeInputWithoutDisable from "components/HeroSearchForm/StayDatesRangeInputWithoutDisable";
// import GuestsInput from "components/HeroSearchForm2Mobile/GuestsInput";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import GuestsRoomInput from "components/HeroSearchForm/GuestsRoomInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export interface ListingStayProps {
  className?: string;
}

const ListingStay: FC<ListingStayProps> = ({ className = "" }) => {
  const intl = useIntl()
  const history = useHistory();
  const {
    pending: catDataPending,
    category,
    error: catDataError,
  } = useSelector((state: RootState) => {
    return state.category;
  });

  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const [configData, setConfigData] = useState<ConfigData>();

  const [catItemList, setCatItemList] = useState<CategoryData[]>([]);
  const [photos, setPhotos] = useState<string[]>([]);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [roomValue, setRoomValue] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  useEffect(() => {
    if (category) {
      setCatItemList(category);
      let list = [];
      category.forEach((catItem) => {
        list = [...list, ...catItem.catImages.map((img) => img.img_url)];
      });
      setPhotos(list);
    }
  }, [category]);

  useEffect(() => {
    // dateRangeValue
    // const dateValue = history?.location?.state && history?.location?.state?.dateRangeValue

    const stateValue = history.location.state as
      | { startDate: Date; endDate: Date }
      | undefined;
    const dateObj = {
      startDate: moment((stateValue &&stateValue.startDate) ? stateValue.startDate : moment().add(4, "days")),
      endDate: moment((stateValue && stateValue.endDate) ? stateValue.endDate: moment().add(10, "days")),
    };
    setSelectedDate(dateObj);
  }, []);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6 flex">
        <div className="flex justify-start items-center">
          <div className="text-neutral-300 dark:text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nc-icon-field"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div className="line-clamp-1 font-semibold pl-2">
            {configData?.biz_address1}
          </div>
        </div>
        <div className="line-clamp-1 pl-2">{configData?.biz_desc}</div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <div className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInputWithoutDisable
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => setSelectedDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* <GuestsInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
            hasButtonSubmit={false}
          /> */}
          {/* <GuestsRoomInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            roomValue={roomValue}
            setRoomValue={setRoomValue}
            hasButtonSubmit={false}
          /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>HMS || Hotel Management System</title>
      </Helmet>

      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold p-4">
            {configData?.biz_name}
          </h2>
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            {photos && (
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={photos[0]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
            )}
            {photos &&
              photos
                .filter((_, i) => i >= 1 && i < 5)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? "hidden sm:block" : ""
                    }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item || ""}
                    />

                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
              {intl.formatMessage({ id: "Show-all-photos" })}
              </span>
            </div>
          </div>

          {photos && (
            <ModalPhotos
              imgs={photos}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
              uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
            />
          )}

          <div>
            <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
              {/* CONTENT */}
              <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                {renderSection1()}
              </div>

              {/* SIDEBAR */}
              <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                <div className="sticky top-28">{renderSidebar()}</div>
              </div>
            </main>
          </div>

          <SectionGridFeaturePlacesList
            stayListings={catItemList}
            roomValue={roomValue}
            selectedDate={selectedDate}
          />
        </header>
      </>
    </div>
  );
};

export default ListingStay;
