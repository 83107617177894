import { loginTypes } from "../../ActionTypes/authTypes";
import {
  PostLoginFailure,
  PostLoginFailurePayload,
  PostLoginRequest,
  PostLoginRequestPayload,
  PostLoginSuccess,
  PostLoginSuccessPayload,
  LogoutRequest,
} from "../../types/auth";


export const postLoginRequest = (user: PostLoginRequestPayload): PostLoginRequest => ({
    type: loginTypes.POST_LOGIN_REQUEST,
    payload: user,
  });
  
  export const postLoginSuccess = (
    payload: PostLoginSuccessPayload
  ): PostLoginSuccess => ({
    type: loginTypes.POST_LOGIN_SUCCESS,
    payload,
  });
  
  export const postLoginFailure = (
    payload: PostLoginFailurePayload
  ): PostLoginFailure => ({
    type: loginTypes.POST_LOGIN_FAILURE,
    payload,
  });

  export const logoutRequest = (): LogoutRequest => {
    return ({
      type: loginTypes.LOGOUT_REQUEST,
    });}