import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { SmLink } from "types/initial";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
  smLink: SmLink[]
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: "lab la-facebook-square", href: "#" },
  { name: "Twitter", icon: "lab la-twitter", href: "#" },
  { name: "Youtube", icon: "lab la-youtube", href: "#" },
  { name: "Instagram", icon: "lab la-instagram", href: "#" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
  smLink
}) => {

  const getIcon = (sm: SmLink) => {
    if (sm.sm_code === "FACEBOOK") {
      return "lab la-facebook-square";
    } else if (sm.sm_code === "TWITTER") {
      return "lab la-twitter";
    } else if (sm.sm_code === "INSTAGRAM") {
      return "lab la-instagram";
    } else if (sm.sm_code === "YOUTUBE") {
      return "lab la-youtube";
    } else {
      return "";
    }
  };
  const socialsLinks = smLink.map((sm) => ({
    sm,
    icon: getIcon(sm),
    href: sm.sm_link,
    name:
      sm.sm_code.charAt(0).toUpperCase() + sm.sm_code.slice(1).toLowerCase(),
  }));

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socialsLinks.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <i className={item.icon}></i>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
