import { loginOverlayTypes } from "../../ActionTypes/initialTypes";
import { LoginOverlayActions , LoginOverlayState} from "../../types/types";

const initialState: LoginOverlayState = {
  pending: false,
  value: false
};

export default (state = initialState, action: LoginOverlayActions) => {
  switch (action.type) {
    case loginOverlayTypes.LOGIN_OVERLAY:
      return {
        ...state,
        value: action.payload.value,
        pending: false
      };
    
    default:
      return {
        ...state
      };
  }
};