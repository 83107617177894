import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { CategoryRoom, StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useIntl } from "react-intl";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { RouterProps, withRouter, useHistory } from "react-router-dom";

export interface PropertyCardHProps {
  className?: string;
  data?: CategoryRoom;
  size?: "default" | "small";
  selectedDate: DateRage;
  roomValue: number;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardHorizontal: FC<PropertyCardHProps> = ({
  size = "default",
  className = "",
  data,
  selectedDate,
  roomValue,
}) => {
  const history = useHistory();
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);
  const { title, descp, avg_price_per_day, catImages, id } = data;

  const intl = useIntl();

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={catImages}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          //   href={href}
        />
      </div>
    );
  };

  const getPrice = (val: string, catItemData) => {
    return val === "0.00" ? catItemData?.avg_price_per_day : val;
  };

  const returnValueBasedOnDay = (day: string, catItemData) => {
    if (day === "Sunday") {
      return getPrice(catItemData?.price_for_sunday, catItemData);
    } else if (day === "Monday") {
      return getPrice(catItemData?.price_for_monday, catItemData);
    } else if (day === "Tuesday") {
      return getPrice(catItemData?.price_for_tuesday, catItemData);
    } else if (day === "Wednesday") {
      return getPrice(catItemData?.price_for_wed, catItemData);
    } else if (day === "Thursday") {
      return getPrice(catItemData?.price_for_thursday, catItemData);
    } else if (day === "Friday") {
      return getPrice(catItemData?.price_for_friday, catItemData);
    } else if (day === "Saturday") {
      return getPrice(catItemData?.price_for_sat, catItemData);
    }
  };

  const getPriceValue = (catItemData) => {
    let price = 0;
    const dayDiff = Number(
      selectedDate.endDate?.diff(selectedDate.startDate, "days")
    );
    for (let i = 0; i < dayDiff; i++) {
      const dayPrice = returnValueBasedOnDay(
        moment(moment(selectedDate?.startDate).add(i, "days"))?.format("dddd"),
        catItemData
      );
      price = price + Number(dayPrice);
    }
    return price / dayDiff;
  };

  const totalValue =
    getPriceValue(data) *
    (selectedDate.endDate !== null && selectedDate.startDate !== null
      ? Number(selectedDate.endDate?.diff(selectedDate.startDate, "days"))
      : 0) *
    roomValue;

  const serviceCharge = config
    ? (Number(config.tax_percentage) * totalValue) / 100
    : 0;

  const handleReserve = () => {
    history.push(`/listing-stay-detail/${id}`);
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full flex justify-between">
          <div>
            <div className="flex items-start space-x-2">
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{title}</span>
              </h2>
            </div>
            <span className="line-clamp-1 sub-abouts-title">{descp}</span>
          </div>
          <div>
            <span className="text-base font-semibold">
               {totalValue + serviceCharge} {intl.formatMessage({ id: "SAR" })} 
              {` `}
              {size === "default" &&  (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal normal-fonts-btn   ">
                  /{intl.formatMessage({ id: "night" })}
                </span>
              )}
            </span>
          </div>
        </div>
        <div className="flex w-full justify-end items-end ">
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
          <span
            onClick={() => handleReserve()}
            className="reserve-btn flex items-center hover:cursor-pointer justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500"
          >
            {intl.formatMessage({ id: "Reserve" })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH mt-6 group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col sm:flex-row stay-listing-btn-main">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={true}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </div>
  );
};

export default PropertyCardHorizontal;
