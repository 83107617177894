import { registerTypes } from "../../ActionTypes/authTypes";
import {
  PostRegisterFailure,
  PostRegisterFailurePayload,
  PostRegisterRequest,
  PostRegisterRequestPayload,
  PostRegisterSuccess,
  PostRegisterSuccessPayload,
} from "../../types/auth";

export const postRegisterRequest = (
  user: PostRegisterRequestPayload
): PostRegisterRequest => ({
  type: registerTypes.POST_REGISTER_REQUEST,
  payload: user,
});

export const postRegisterSuccess = (
  payload: PostRegisterSuccessPayload
): PostRegisterSuccess => ({
  type: registerTypes.POST_REGISTER_SUCCESS,
  payload,
});

export const postRegisterFailure = (
  payload: PostRegisterFailurePayload
): PostRegisterFailure => ({
  type: registerTypes.POST_REGISTER_FAILURE,
  payload,
});
