import { loginTypes } from "../../ActionTypes/authTypes";
import { LoginActions, LoginState} from "../../types/auth";

const initialState: LoginState = {
  pending: false,
  data: null,
  status: null,
  error: null
};

export default (state = initialState, action: LoginActions) => {
  switch (action.type) {
    case loginTypes.POST_LOGIN_REQUEST:
      return {
        ...state,
        pending: true
      };
    case loginTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.data,
        status: action.payload.status,
        error: null
      };
    case loginTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error
      };
    case loginTypes.LOGOUT_REQUEST:
      return {
        pending: false,
        data: null,
        status: null,
        error: null
      }
    default:
      return {
        ...state
      };
  }
};
