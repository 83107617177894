import axios from "axios";
import {
  PostLoginRequestPayload,
  PostRegisterRequestPayload,
} from "redux/types/auth";
import {
  HotelBookingProcessPayload,
  PaymentUpdatePayload,
  PostHotelReviewPayload,
} from "../types/initial";
import { baseUrl } from "./baseURL";

const middelURL = "api/hotel/";

const APIURL = `${baseUrl}/${middelURL}`;

const getConfig = (id: string) => {
  const url = `${APIURL}get-config/${id}`;
  return axios.get(url);
};

const getSmLink = (id: string) => {
  const url = `${APIURL}get-sm-links/${id}`;
  return axios.get(url);
};

const getCatData = (id: string) => {
  const url = `${APIURL}get-cat-data/${id}`;
  return axios.get(url);
};

const getUIData = (id: string) => {
  const url = `${APIURL}get-ui-data/${id}`;
  return axios.get(url);
};

const getAmenities = (id: string) => {
  const url = `${APIURL}get-amenities/${id}`;
  return axios.get(url);
};

const loginUser = (user: PostLoginRequestPayload) => {
  const url = `${APIURL}cust-login`;
  return axios.post(url, user);
};

const registerUser = (user: PostRegisterRequestPayload) => {
  const url = `${APIURL}cust-registration`;
  return axios.post(url, user);
};

const hotelBookingProcess = (user: HotelBookingProcessPayload) => {
  const tocken = localStorage.getItem("auth") as string;
  const url = `${APIURL}hotel-booking-process`;

  return axios.post(url, user, {
    headers: {
      token: tocken,
    },
  });
};
const paymentUpdate = (data: PaymentUpdatePayload) => {
  const tocken = localStorage.getItem("auth") as string;
  const url = `${APIURL}hotel-pay-update`;
  return axios.post(url, data, {
    headers: {
      token: tocken,
    },
  });
};

const getCodeDomain = (host: string) => {
  const url = `${APIURL}get-code-domain`;
  return axios.post(url, {
    domainName: host
  });
};

const getHotelReview = (id: string) => {
  const url = `${APIURL}hotel-review/${id}`;
  return axios.get(url);
};

const postHotelReview = (id:string, payload :PostHotelReviewPayload) => {
  const url = `${APIURL}hotel-review/${id}`;
  return axios.post(url, payload);
};

const getMyProfile = () => {
  const tocken = localStorage.getItem("auth") as string;
  const url = `${APIURL}my-profile`;
  return axios.get(url, {
    headers: {
      token: tocken,
    },
  });
};

const getBookingHistory = () => {
  const tocken = localStorage.getItem("auth") as string;
  const url = `${APIURL}booking-history`;
  return axios.get(url, {
    headers: {
      token: tocken,
    },
  });
};

const getHotelAddOn = (id: string) => {
  const url = `${APIURL}hotel-add-on/${id}`;
  return axios.get(url);
};


export {
  getConfig,
  getSmLink,
  getCatData,
  getUIData,
  getAmenities,
  loginUser,
  registerUser,
  hotelBookingProcess,
  paymentUpdate,
  //getCodeDomain,
  getHotelReview,
  postHotelReview,
  getMyProfile,
  getBookingHistory,
  getHotelAddOn
};
