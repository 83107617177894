import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import hasError from "utils/utilFunctions";
import Joi from "@hapi/joi";
import { postLoginRequest, postLoginSuccess } from "redux/actions/auth/login";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { RouterProps , withRouter} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from "react-intl";

export interface PageLoginProps {
  className?: string;
  history: RouterProps["history"];
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

type User = {
  email: string;
  password: string;
};

type UserError = {
  email?: string;
  password?: string;
};

const INITIAL_USER = {
  email: "",
  password: "",
};

const INITIAL_USER_ERROR = {
  email: undefined,
  password: undefined,
};

const PageLogin: FC<PageLoginProps> = ({ className = "" , history}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    pending,
    data, status,
    error,
  } = useSelector((state: RootState) => state.login);

  const [user, setUser] = useState<User>(INITIAL_USER);
  const [userErrors, setUserErrors] = useState<UserError>(INITIAL_USER_ERROR);

  function updateUser(inputKey: keyof User, value: string) {
    setUser({
      ...user,
      [inputKey]: value,
    });
  }

  useEffect(() => {
    if(status){
      localStorage.setItem('auth', data)
      setUser(INITIAL_USER)
      const code = localStorage.getItem('code');

      const isRedirectToPay = localStorage.getItem('roomItem')
      const stateValue = history.location.state as {from : string } | undefined;
      if(isRedirectToPay && stateValue){
        history.push((stateValue && stateValue.from )? stateValue.from : `/hotel/${code}`)
      }else{
        toast.success("Login SuccessFully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
        history.push(`/hotel/${code}`)
      }
      dispatch(postLoginSuccess({
        data: null,
        status: null,
      }))
    }else{
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
      dispatch(postLoginSuccess({
        data: null,
        status: null,
      }))
    }
  }, [data])

  function handleSubmit() {
    const requiredString = Joi.string().required().trim().messages({
      "string.empty": "This is a mandatory field",
    });

    const emailString = requiredString.email({ tlds: false }).messages({
      "string.email": "Please Enter Valid Email",
    });

    const errors = {
      email: emailString.validate(user.email).error?.message,
      password: requiredString.validate(user.password).error?.message,
    };

    setUserErrors(errors);
    const hasErr = hasError(errors);

    if (!hasErr) {
      dispatch(postLoginRequest(user))
    }
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Hotel Management System</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {intl.formatMessage({ id: "Login" })}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
            {intl.formatMessage({ id: "Email-address" })}
            </span>
            <Input
              type="email"
              className="mt-1"
              value={user.email}
              onChange={(e) => updateUser("email", e.target.value)}
            />
            {userErrors?.email && (
              <div className="text-red-700 text-sm">{userErrors?.email}</div>
            )}
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
            {intl.formatMessage({ id: "Password" })}
              <Link to="/forgot-pass" className="text-sm">
              {intl.formatMessage({ id: "Forgot-password?" })}
              </Link>
            </span>
            <Input
              type="password"
              className="mt-1"
              value={user.password}
              onChange={(e) => updateUser("password", e.target.value)}
            />
            {userErrors?.password && (
              <div className="text-red-700 text-sm">{userErrors?.password}</div>
            )}
          </label>

          <ButtonPrimary loading={pending} onClick={() => handleSubmit()}>{intl.formatMessage({ id: "Continue" })}</ButtonPrimary>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
          {intl.formatMessage({ id: "New-user?" })} {` `}
            <Link to="/signup">{intl.formatMessage({ id: "Create-an-account" })}</Link>
          </span>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        // theme={}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </div>
  );
};

export default withRouter(PageLogin)
