export enum configTypes {
  FETCH_CONFIG_REQUEST = "FETCH_CONFIG_REQUEST",
  FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS",
  FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE",
}

export enum uiDataTypes {
  FETCH_UI_DATA_REQUEST = "FETCH_UI_DATA_REQUEST",
  FETCH_UI_DATA_SUCCESS = "FETCH_UI_DATA_SUCCESS",
  FETCH_UI_DATA_FAILURE = "FETCH_UI_DATA_FAILURE",
}

export enum catDataTypes {
  FETCH_CAT_DATA_REQUEST = "FETCH_CAT_DATA_REQUEST",
  FETCH_CAT_DATA_SUCCESS = "FETCH_CAT_DATA_SUCCESS",
  FETCH_CAT_DATA_FAILURE = "FETCH_CAT_DATA_FAILURE",
}

export enum smLinkTypes {
  FETCH_SM_LINK_REQUEST = "FETCH_SM_LINK_REQUEST",
  FETCH_SM_LINK_SUCCESS = "FETCH_SM_LINK_SUCCESS",
  FETCH_SM_LINK_FAILURE = "FETCH_SM_LINK_FAILURE",
}

export enum aminitiesTypes {
  FETCH_AMINITIES_REQUEST = "FETCH_AMINITIES_REQUEST",
  FETCH_AMINITIES_SUCCESS = "FETCH_AMINITIES_SUCCESS",
  FETCH_AMINITIES_FAILURE = "FETCH_AMINITIES_FAILURE",
}

export enum languageTypes{
  FETCH_LANGUAGE_REQUEST = "FETCH_LANGUAGE_REQUEST",
  FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS",
}

export enum codeTypes{
  FETCH_CODE_REQUEST = "FETCH_CODE_REQUEST",
  FETCH_CODE_SUCCESS = "FETCH_CODE_SUCCESS",
}

export enum loginOverlayTypes{
  LOGIN_OVERLAY = "LOGIN_OVERLAY",
}

export enum hotelAddOnTypes {
  FETCH_HOTEL_ADD_ON_REQUEST = "FETCH_HOTEL_ADD_ON_REQUEST",
  FETCH_HOTEL_ADD_ON_SUCCESS = "FETCH_HOTEL_ADD_ON_SUCCESS",
  FETCH_HOTEL_ADD_ON_FAILURE = "FETCH_HOTEL_ADD_ON_FAILURE",
}
