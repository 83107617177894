import React, { useEffect, useState } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { useIntl } from "react-intl";
import ncNanoId from "utils/ncNanoId";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

function Navigation() {
  const intl = useIntl();
  const {
    code,
  } = useSelector((state: RootState) => {
    return state.code;
  });
  const [NavigationList, setNavigationList] = useState([])
  const isLogin = localStorage.getItem('auth');
  useEffect(()=>{

    let NavigationList = NAVIGATION_DEMO.filter((item) => !(isLogin && item.name === intl.formatMessage({ id: "LOGIN" })))
    const homevalue = {
      id: ncNanoId(),
      href: `/hotel/${code}`,
      name: "HOME",
      isNew: true,
    }
    NavigationList.unshift(homevalue)
    setNavigationList(NavigationList)
  }, [isLogin])
  
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NavigationList.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
