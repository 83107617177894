import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Ar } from "types/initial";
import { RootState } from "redux/reducers/rootReducer";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { CategoryRoom } from "data/types";
import StayCard from "components/StayCard/StayCard";
import GallerySliderHome from "./GallerySliderHome";
import { RouterProps, useHistory } from "react-router-dom";

export interface SectionHeroProps {
  className?: string;
  uiData: Ar;
  history: RouterProps["history"];
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  uiData,
  history,
}) => {
  const { config } = useSelector((state: RootState) => {
    return state.config;
  });
  const { language } = useSelector((state: RootState) => {
    return state.language;
  });
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    const banners = [
      {
        id: 1,
        url: uiData.banner1_img,
        title: uiData.banner1_text,
        key:1
      },
      {
        id: 2,
        url: uiData.banner2_img,
        title: uiData.banner2_text,
        key:2
      },
      {
        id: 3,
        url: uiData.banner3_img,
        title: uiData.banner3_text,
        key:3
      },
    ];
    setBannerList(banners);
  }, [uiData]);

  const renderSliderGallery = () => {
    return (
      <div className=" w-full">
        <GallerySliderHome
          uniqueID={`StayCard_`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={bannerList}
          uiData={uiData}
        
        />
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap_medium  flex">
        <div className="flex justify-start items-center">
          <div className="text-neutral-300 dark:text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nc-icon-field"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div className="line-clamp-1 font-semibold pl-2">
            {config &&
              (language === "ar"
                ? config?.biz_address1_ar
                : config?.biz_address1)}
          </div>
        </div>
        {/* <div className="line-clamp-1 pl-2">{config && config?.biz_desc}</div> */}
      </div>
    );
  };

  return (
    <div>
      <h2 className="royal-text font-medium pt-2 md:text-lg text-neutral-500 !leading-[114%] ">
        {language === "ar" ? config?.biz_name_ar : config?.biz_name}
      </h2>

      <div
        className={`nc-SectionHero flex flex-col-reverse lg:flex-col banner-slider-main relative ${className}`}
        data-nc-id="SectionHero"
      >
        <div className="nc-SectionGridFeaturePlaces relative">
          {renderSliderGallery()}
          {/* <div className="flex mt-16 justify-center items-center"></div> */}
        </div>
        <div className="container hidden lg:block z-10 mb-12 lg:mb-0 lg:mt-1 w-full">
        
          <HeroSearchForm history={history} />
          {/* {renderSection1()} */}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
