import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import GuestsRoomInput from "components/HeroSearchForm/GuestsRoomInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import MobileFooterSticky from "./MobileFooterSticky";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { CategoryData, ConfigData } from "types/initial";
import { useParams } from "react-router-dom";
import { RouterProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { toast, ToastContainer } from "react-toastify";
import { START_DATE, END_DATE } from "react-dates/constants";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  history: RouterProps["history"];
}

type Params = {
  id: string;
};

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
  history,
}) => {
  const intl = useIntl();
  const { id } = useParams<Params>();
  const {
    pending: catDataPending,
    category,
    error: catDataError,
  } = useSelector((state: RootState) => {
    return state.category;
  });

  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const [configData, setConfigData] = useState<ConfigData>();

  const [catItemData, setCatItemData] = useState<CategoryData>();

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  useEffect(() => {
    if (category) {
      const categoryItem = category.find((catItem) => catItem.id === id);
      setCatItemData(categoryItem);
    }
  }, [category]);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const [roomValue, setRoomValue] = useState<number>(1);

  const getPrice = (val: string) => {
    return val === "0.00" ? catItemData?.avg_price_per_day : val;
  };

  const returnValueBasedOnDay = (day: string) => {
    if (day === "Sunday") {
      return getPrice(catItemData?.price_for_sunday);
    } else if (day === "Monday") {
      return getPrice(catItemData?.price_for_monday);
    } else if (day === "Tuesday") {
      return getPrice(catItemData?.price_for_tuesday);
    } else if (day === "Wednesday") {
      return getPrice(catItemData?.price_for_wed);
    } else if (day === "Thursday") {
      return getPrice(catItemData?.price_for_thursday);
    } else if (day === "Friday") {
      return getPrice(catItemData?.price_for_friday);
    } else if (day === "Saturday") {
      return getPrice(catItemData?.price_for_sat);
    }
  };

  const getPriceValue = () => {
    let price = 0;
    const dayDiff = Number(
      selectedDate.endDate?.diff(selectedDate.startDate, "days")
    );
    for (let i = 0; i < dayDiff; i++) {
      const dayPrice = returnValueBasedOnDay(
        moment(moment(selectedDate?.startDate).add(i, "days"))?.format("dddd")
      );
      price = price + Number(dayPrice);
    }
    return Number((price / dayDiff).toFixed(2)) || 0;
  };

  const Photos =
    catItemData &&
    catItemData?.catImages &&
    catItemData?.catImages.map((img) => img.img_url);

  const totalValue =
    getPriceValue() *
    (selectedDate.endDate !== null && selectedDate.startDate !== null
      ? Number(selectedDate.endDate?.diff(selectedDate.startDate, "days"))
      : 0) *
    roomValue;

  const serviceCharge = configData
    ? Number(
      ((Number(configData.tax_percentage) * totalValue) / 100).toFixed(2)
    )
    : 0;

  const windowSize = useWindowSize();

  useEffect(() => {
    const payItemData =
      localStorage.getItem("roomItem") &&
        localStorage.getItem("roomItem") !== null
        ? JSON.parse(localStorage.getItem("roomItem") as string)
        : undefined;
    if (payItemData && payItemData.catItemData.id === id) {
      setRoomValue(payItemData.roomValue);
      setSelectedDate({
        startDate: moment(payItemData.selectedDate.startDate),
        endDate: moment(payItemData.selectedDate.endDate),
      });
    }
  }, []);

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const handleReserve = () => {
    const payItemData = {
      roomValue: roomValue,
      selectedDate: selectedDate,
      catItemData: catItemData,
      finalAmount: totalValue + serviceCharge,
      totalAmount: totalValue,
      serviceCharge: serviceCharge,
      priceValue: getPriceValue(),
    };
    localStorage.setItem("roomItem", JSON.stringify(payItemData));
    // const tocken = localStorage.getItem("auth");
    history.push(`/checkout`);
    // if (tocken) {
    //   history.push(`/checkout/${id}`);
    // } else {
    //   history.push({
    //     pathname: "/login",
    //     state: { from: history.location.pathname },
    //   });
    // }
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {/* <Badge name="Wooden house" /> */}
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {catItemData?.title}
        </h2>

        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{catItemData?.descp}</span>
        </div>
        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          {/* <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span> */}
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
            accommodation, an outdoor swimming pool, a bar, a shared lounge, a
            garden and barbecue facilities. Complimentary WiFi is provided.
          </span>
          <br />
          <br />
          <span>
            There is a private bathroom with bidet in all units, along with a
            hairdryer and free toiletries.
          </span>
          <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">
            {intl.formatMessage({ id: "Room-Rates" })}{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Sunday</span>
              <span>SAR {getPrice(catItemData?.price_for_sunday)}</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday</span>
              <span>SAR {getPrice(catItemData?.price_for_monday)}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Tuesday</span>
              <span>SAR {getPrice(catItemData?.price_for_tuesday)}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Wednesday</span>
              <span>SAR {getPrice(catItemData?.price_for_wed)}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Thursday</span>
              <span>SAR {getPrice(catItemData?.price_for_thursday)}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday</span>
              <span>SAR {getPrice(catItemData?.price_for_friday)}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Saturday</span>
              <span>SAR {getPrice(catItemData?.price_for_sat)}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Hourly</span>
              <span>SAR {getPrice(catItemData?.price_for_hour)}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Weekly</span>
              <span>SAR {getPrice(catItemData?.price_for_weekly)}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monthly</span>
              <span>SAR {getPrice(catItemData?.price_for_monthly)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDay = (day) => {
    const isDateFound = catItemData.amountByDays.find(
      (date) =>{
      return  moment(date.date ,"yyyy-MM-DD").format("yyyy-MM-DD") ===
        (day.format("yyyy-MM-DD") as string)
      } 
    );
    if (isDateFound) {
      return !isDateFound.isavailable ? (
        <div style={{ fontSize: "16px" }}>{day.format("DD")}</div>
      ) : (
        <>
          <div style={{ fontSize: "16px" }}>{day.format("DD")}</div>
          <div className="font-sm" style={{ fontSize: "10px" }}>
            SAR {isDateFound.price}
          </div>
        </>
      );
    }
    return <div>{day.format("DD")}</div>;
  };

  const isDayDisabled = (day) => {
    const isDateFound = catItemData.amountByDays.find(
      (date) =>
        moment(date.date).format("yyyy-MM-DD") ===
        (day.format("yyyy-MM-DD") as string)
    );

    if (isDateFound) {
      return !isDateFound.isavailable;
    }
    return false;
  };
  const dateSelection = (date) => {
    let isBetween = false;
    if (date.startDate && date.endDate) {
      let blockDates = catItemData.amountByDays.filter((x) => !x.isavailable)
      for (const blockDate of blockDates) {
        isBetween = moment(blockDate.date).isBetween(date.startDate, date.endDate)
        if (isBetween) {
          let sDate = moment();
          if (isDayDisabled(moment())) {
            for (const day of [1, 2, 3, 4, 5, 6, 7]) {
              if (isDayDisabled(moment().add(day, "days"))) {

              } else {
                sDate = moment().add(day, "days");
                break;
              }
            }
          }
          setSelectedDate({ startDate: sDate, endDate: null })
          setFocusedInputSectionCheckDate(null)
          toast.error("Range you have selected is not available!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          break;
        }
      }
      if (!isBetween)
        setSelectedDate(date)
    } else {
      setSelectedDate(date)
    }
  }
  const isOutSideRange = (day) => {
    return !(
      moment(day).isAfter(
        moment(catItemData.amountByDays[0].date).subtract(1, "days")
      ) &&
      moment(day).isBefore(
        moment(
          catItemData.amountByDays[catItemData.amountByDays.length - 1].date
        ).add(1, "days")
      )
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span> */}
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => dateSelection(date)}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) => {
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              }
              initialVisibleMonth={null}
              numberOfMonths={2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel={false}
              // orientation={windowSize.width < 500 ? 'vertical' : 'horizontal'}
              // isOutsideRange={(day) => isOutSideRange(day)}
              // isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
              renderDayContents={(date) => renderDay(date)}
              isDayBlocked={(date) => isDayDisabled(date)}
              navNext={false}
              navPrev={false}
            />
            {/* [moment(catItemData.amountByDays[0].date), moment(catItemData.amountByDays[catItemData.amountByDays.length - 1].date)] */}
            {/* [selectedDate.startDate, selectedDate.endDate].includes(date) */}
            {/* <DayPicker
        className="Range"
        numberOfMonths={2}
        selected={[{ from: selectedDate.startDate.toDate(), to: selectedDate.endDate.toDate() }]}
        // modifiers={modifiers}
        // modifiersStyles={modifiersStyles}
        // onDayClick={this.handleDayClick}
        // renderDay={(date) => renderDay(date)}
        components={
          DayContent : renderDay(date)
        }        
        // canChangeMonth={false}
      /> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {intl.formatMessage({ id: "Things-to-know" })}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          {/* <h4 className="text-lg font-semibold">Cancellation policy</h4> */}
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {catItemData?.ins_policy_desc}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            {intl.formatMessage({ id: "Check-in-time" })}
          </h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>{intl.formatMessage({ id: "Check-in" })}</span>
              <span>{configData?.check_in}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>{intl.formatMessage({ id: "Check-out" })}</span>
              <span>{configData?.check_out}</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          <h4 className="text-lg font-semibold">
            {intl.formatMessage({ id: "Check-in-policy" })}
          </h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {configData?.check_in_policy}
          </span>
        </div>
        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl listingSectionSidebar-text ">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            SAR {getPriceValue()}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /{intl.formatMessage({ id: "night" })}
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <div className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => dateSelection(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
            isDayDisabled={isDayDisabled}
            isOutSideRange={isOutSideRange}
            renderDay={renderDay}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
            hasButtonSubmit={false}
          />
          <GuestsRoomInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            roomValue={roomValue}
            setRoomValue={setRoomValue}
            hasButtonSubmit={false}
          />
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              SAR {getPriceValue()} x{" "}
              {selectedDate.startDate && selectedDate.endDate
                ? selectedDate.endDate?.diff(selectedDate.startDate, "days")
                : 0}{" "}
              {intl.formatMessage({ id: "night" })} x {roomValue}{" "}
              {intl.formatMessage({ id: "room" })}
            </span>
            <span>SAR {totalValue}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{intl.formatMessage({ id: "Service-charge" })}</span>
            <span>SAR {serviceCharge}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>SAR {totalValue + serviceCharge}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          disabled={
            !(
              roomValue &&
              selectedDate.startDate &&
              selectedDate.endDate &&
              configData
            )
          }
          onClick={() => handleReserve()}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div>
      {catItemData && (
        <div
          className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
          data-nc-id="ListingStayDetailPage"
        >
          {/* SINGLE HEADER */}
          <>
            <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                {Photos && (
                  <div
                    className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                    onClick={() => handleOpenModal(0)}
                  >
                    {/* <NcImage
                      containerClassName="absolute inset-0"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={Photos[0]}
                    /> */}
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={Photos[0]}
                    />
                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                  </div>
                )}
                {Photos &&
                  Photos.filter((_, i) => i >= 1 && i < 5).map(
                    (item, index) => (
                      <div
                        key={index}
                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                          }`}
                      >
                        <NcImage
                          containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl "
                          src={item || ""}
                        />

                        {/* OVERLAY */}
                        <div
                          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                          onClick={() => handleOpenModal(index + 1)}
                        />
                      </div>
                    )
                  )}

                <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                  onClick={() => handleOpenModal(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </div>
              </div>
            </header>
            {/* MODAL PHOTOS */}
            {Photos && (
              <ModalPhotos
                imgs={Photos}
                isOpen={isOpen}
                onClose={handleCloseModal}
                initFocus={openFocusIndex}
                uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
              />
            )}
          </>

          {/* MAIn */}
          <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
            {/* CONTENT */}
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {renderSection1()}
              {/* {renderSection2()} */}
              {/* {renderSection3()} */}
              {/* {renderSection4()} */}
              {renderSectionCheckIndate()}
              {/* {renderSection5()} */}
              {/* {renderSection6()} */}
              {/* {renderSection7()} */}
              {renderSection8()}
            </div>

            {/* SIDEBAR */}
            <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
              <div className="sticky top-28">{renderSidebar()}</div>
            </div>
          </main>
          <ToastContainer />

          {/* STICKY FOOTER MOBILE */}
          {!isPreviewMode && (
            <MobileFooterSticky
              getPriceValue={getPriceValue}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              isDayDisabled={isDayDisabled}
              // isOutSideRange={!isInclusivelyAfterDay(day, moment())}
              renderDay={renderDay}
              handleReserve={handleReserve}
            />
          )}

          {/* OTHER SECTION */}
          {!isPreviewMode && (
            <div className="container py-24 lg:py-32">
              {/* SECTION 1 */}
              {/* <div className="relative py-16">
                <BackgroundSection />
                <SectionSliderNewCategories
                  heading="Explore by types of stays"
                  subHeading="Explore houses based on 10 types of stays"
                  categoryCardType="card5"
                  itemPerRow={5}
                  sliderStyle="style2"
                  uniqueClassName={"ListingStayDetailPage1"}
                />
              </div> */}

              {/* SECTION */}
              <SectionSubscribe2 className="pt-24 lg:pt-32" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(ListingStayDetailPage);
