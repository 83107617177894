import { loginOverlayTypes } from "../../ActionTypes/initialTypes";
import {
FetchLoginOverlayRequest,
  FetchLanguageDataSuccess,
  LoginOverlayPayload,
} from "../../types/types";


export const loginOverlay = (
   
  payload: LoginOverlayPayload
): FetchLoginOverlayRequest => (
    {
    
    type: loginOverlayTypes.LOGIN_OVERLAY,
    payload,
});


