import { languageTypes } from "../../ActionTypes/initialTypes";
import {
  FetchLanguageDataRequest,
  FetchLanguageDataSuccess,
  FetchLanguageDataSuccessPayload,
} from "../../types/types";


export const fetchLanguageRequest = (
  language: "en" | "ar"
): FetchLanguageDataRequest => ({
  type: languageTypes.FETCH_LANGUAGE_REQUEST,
  payload: { language: language },
});

export const fetchLanguageSuccess = (
  payload: FetchLanguageDataSuccessPayload
): FetchLanguageDataSuccess => ({
  type: languageTypes.FETCH_LANGUAGE_SUCCESS,
  payload,
});
