import SectionHero from "./SectionHeroSlider";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect, useState } from "react";
import { RouterProps, useParams, withRouter } from "react-router-dom";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionPolicy from "components/SectionPolicy/SectionPolicy";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { fetchConfigRequest } from "redux/actions/initial/initialActions";
import { fetchUiDataRequest } from "redux/actions/initial/getUiDataActions";
import { fetchCatDataRequest } from "redux/actions/initial/getCatDataActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { fetchSmLinkDataRequest } from "redux/actions/initial/getSmLinkAction";
import { Ar, CategoryData, ConfigData, SmLink, UIData } from "types/initial";
import { fetchLanguageRequest } from "redux/actions/initial/getLanguageActions";
import { fetchAnimitesRequest } from "redux/actions/initial/getAminitesActions";
import { fetchCodeRequest } from "redux/actions/initial/getCodeAction";
import Reviews from "./Reviews";

type Params = {
  id: string;
};

type Props = {
  history: RouterProps["history"];
};

function PageHome(props: Props) {
  const { history } = props;
  const dispatch = useDispatch();
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const {
    pending: uiDataPending,
    ui,
    error: uiDataError,
  } = useSelector((state: RootState) => {
    return state.ui;
  });

  const {
    pending: catDataPending,
    category,
    error: catDataError,
  } = useSelector((state: RootState) => {
    return state.category;
  });

  const { language } = useSelector((state: RootState) => {
    return state.language;
  });

  let { id } = useParams<Params>();

  const [configData, setConfigData] = useState<ConfigData>();
  const [uiData, setUiData] = useState<Ar>();
  const [catData, setCatData] = useState<CategoryData[]>([]);

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  useEffect(() => {
    ui && setUiData(language === "en" ? ui.en : ui.ar);
  }, [ui, language]);

  useEffect(() => {
    category && setCatData(category);
  }, [category]);

  useEffect(() => {
    console.log(id);
    if (
      !id &&
      localStorage.getItem("code") &&
      localStorage.getItem("code") !== undefined &&
      localStorage.getItem("code") !== ""
    ) {
      id = localStorage.getItem("code");
      console.log("hi")
    }

    if (id) {
      localStorage.setItem("code", id);
      dispatch(fetchCodeRequest(id));
      localStorage.removeItem("roomItem");
      localStorage.removeItem("bookingno");
      localStorage.removeItem("state");
      dispatch(fetchConfigRequest(id));
      dispatch(fetchSmLinkDataRequest(id));
      dispatch(fetchCatDataRequest(id));
      dispatch(fetchUiDataRequest(id));
      dispatch(fetchAnimitesRequest(id));
      // dispatch(fetchLanguageRequest('en'))
    }
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      {uiData && (
        <SectionHero
          className="pt-2 lg:pt-4"
          uiData={uiData}
          history={history}
        />
      )}

      {/* SECTION 1 */}
      {/* <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        /> */}

      {/* SECTION2 */}
      {/* <SectionOurFeatures /> */}
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces stayListings={catData} />
        </div>

        <div className="relative py-16 margin-top">
          {/* <BackgroundSection /> */}
          <SectionHowItWork />
        </div>

        <Reviews />

        {/* SECTION */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}
        {/* <SectionPolicy /> */}
        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* {uiData && <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" uiData={uiData} />
        </div>} */}
      </div>
    </div>
  );
}

export default withRouter(PageHome);
