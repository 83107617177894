/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { IntlGlobalProvider } from "../../components/IntlGlobalProvider";
import { RootState } from "redux/reducers/rootReducer";

type Props = {
  messages: any;
  children: JSX.Element;
};
export function LanguageProvider(props: Props) {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => {
    return state.language;
  });

  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute("dir", language === 'ar' ? 'rtl': 'ltr');
    document.body.style.setProperty('font-family', language === 'ar'? 'Tajawal,sans-serif' :'Montserrat, sans-serif')
  }, [ language]);

  const { messages } = props;

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={messages[language]}
    >
      <IntlGlobalProvider>
        {React.Children.only(props.children)}
      </IntlGlobalProvider>
    </IntlProvider>
  );
}
