import React, { useEffect, useState } from "react";
import { ConfigData } from "types/initial";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

import FloatingWhatsApp from "react-floating-whatsapp";

const Footer: React.FC = () => {
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const [configData, setConfigData] = useState<ConfigData>();

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  return (
    <>
      {configData?.notify_whatsapp_contact ? (
        <FloatingWhatsApp
          phoneNumber={configData?.notify_whatsapp_contact}
          accountName={configData?.biz_name}
          avatar={configData?.biz_logo_url}
          darkMode={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Footer;
