import { aminitiesTypes } from "../../ActionTypes/initialTypes";
import {
AminitiesActions, AminitiesState
} from "../../types/types";


const initialState: AminitiesState  = {
  pending: false,
  aminities: null,
  error: null,
};

export default (
  state = initialState,
  action: AminitiesActions
) => {
  switch (action.type) {
    case aminitiesTypes.FETCH_AMINITIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case aminitiesTypes.FETCH_AMINITIES_SUCCESS:
      return {
        ...state,
        pending: false,
        aminities: action.payload.aminities,
        error: null,
      };
    case aminitiesTypes.FETCH_AMINITIES_FAILURE:
      return {
        ...state,
        pending: false,
        aminities: null,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};
