import { combineReducers } from "redux";
import initialReducer from "./initial/initalReducer";
import uiDataReducer from "./initial/getUiDataReducer"
import catDataReducer from "./initial/getCatDataReducer"
import smLinkDataReducer from "./initial/getSmLinkDataReducer"
import languageDataReducer from "./initial/getLanguageDataReducer"
import loginReducer from "./auth/login"
import registerReducer from "./auth/register"
import navigationReducer from "./initial/navigationReducer";
import aminitiesReducer from "./initial/getAminitiesReducer"
import codeReducer from "./initial/getCodeReducer"
import myProfileReducer from "./auth/myProfile"
import hotelAddOnReducer  from "./initial/getHotelAddOnReducer"

const rootReducer = combineReducers({
  code: codeReducer,
  config: initialReducer,
  ui: uiDataReducer,
  category: catDataReducer,
  smLink: smLinkDataReducer,
  aminities: aminitiesReducer,
  language: languageDataReducer,
  login:loginReducer,
  register: registerReducer,
  navigation: navigationReducer,
  myProfile: myProfileReducer,
  hotelAddOn: hotelAddOnReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
