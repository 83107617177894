import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { CategoryRoom } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, NavLink } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

export interface StayCardProps {
  className?: string;
  data: CategoryRoom;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const intl = useIntl();
  const { language } = useSelector((state: RootState) => {
    return state.language;
  });
  const {
    title,
    title_ar,
    descp,
    descp_ar,
    avg_price_per_day,
    catImages,
    id,
    city,
    city_ar,
    district,
    district_ar,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={catImages}
          href={`/listing-stay-detail/${id}`}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3 z-[1]" />
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      
      <Link to={`/listing-stay-detail/${id}`} id="uniquekey">
        <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
          <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {/* {listingCategory.name} · {bedrooms} beds */}
            </span>
            <div className="flex items-center space-x-2">
              {/* {isAds && <Badge name="ADS" color="green" />} */}
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                {language === "ar" ? (
                  <span className="line-clamp-1">{title_ar}</span>
                ) : (
                  <span className="line-clamp-1">{title}</span>
                )}
              </h2>
            </div>
            {language === "ar" ? (
              <span className="line-clamp-1">
                {city_ar} - {district_ar}
              </span>
            ) : (
              <span className="line-clamp-1">
                {city} - {district}
              </span>
            )}
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              {/* {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )} */}
              {/* <span className="">{address}</span> */}
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {avg_price_per_day} {intl.formatMessage({ id: "SAR" })}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  / {intl.formatMessage({ id: "night" })}
                </span>
              )}
            </span>
            {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <div>{renderContent()}</div>
    </div>
  );
};

export default StayCard;
