import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { RootState } from "redux/reducers/rootReducer";
import moment from "moment";
import { FC } from "react";
import { RouterProps } from "react-router-dom";
import StayDatesRangeInputWithoutDisable from "./StayDatesRangeInputWithoutDisable";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
  setLocationInputValue: React.Dispatch<React.SetStateAction<string>>;
  locationInputValue: string,
  guestValue: any,
  setGuestValue: React.Dispatch<React.SetStateAction<any>>,
  dateRangeValue: DateRage,
  setDateRangeValue: React.Dispatch<React.SetStateAction<DateRage>>,
  history: RouterProps["history"];
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Tokyo, Jappan";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  guestInfants: 1,
};

const StaySearchForm: FC<StaySearchFormProps> = ({
  locationInputValue,
  setLocationInputValue,
  haveDefaultValue = true,
  guestValue,
  setGuestValue,
  dateRangeValue,
  setDateRangeValue,
  history
}) => {
  const { config } = useSelector((state: RootState) => {
    return state.config;
  });


  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  useEffect(() => {
   
    if (haveDefaultValue) {
      //setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
      //setGuestValue(defaultGuestValue);
    }
  }, []);

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => setLocationInputValue(e)}
          onInputDone={() => setDateFocused("startDate")}
          className="flex-[1.5]"
        />
        <StayDatesRangeInputWithoutDisable
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => setDateRangeValue(data)}
          className="flex-[2]"
        />
        <GuestsInput
          defaultValue={guestValue}
          onChange={(data) => setGuestValue(data)}
          className="flex-[0.2]"
          dateRangeValue={dateRangeValue}
          history={history}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
