import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { RootState } from "redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { ConfigData } from "types/initial";
import { useIntl } from "react-intl";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const intl = useIntl();
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);


  const [configData, setConfigData] = useState<ConfigData>();

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Hotel Management System</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {intl.formatMessage({ id: "Contact" })}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {/* {info.map((item, index) => ( */}
                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🗺 {intl.formatMessage({ id: "ADDRESS" })}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {configData?.biz_address1}
                  </span>
                </div>
                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  💌 {intl.formatMessage({ id: "EMAIL" })}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {configData?.biz_email1}
                  </span>
                </div>
                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ {intl.formatMessage({ id: "PHONE" })}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {configData?.biz_phone1}
                  </span>
                </div>
              <div>
                {/* <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3> */}
                {/* <SocialsList className="mt-2" /> */}
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>{intl.formatMessage({ id: "Full-name" })}</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>{intl.formatMessage({ id: "Email-address" })}</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>{intl.formatMessage({ id: "Message" })}</Label>

                  <Textarea className="mt-1" rows={6} />
                </label>
                <div>
                  <ButtonPrimary type="submit">{intl.formatMessage({ id: "Send-Message" })}</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div> */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default PageContact;
