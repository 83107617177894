import { myProfileTypes } from "../../ActionTypes/authTypes";
import {
  GetMyProfileFailure,
  GetMyProfileFailurePayload,
  GetMyProfileRequest,
  GetMyProfileRequestPayload,
  GetMyProfileSuccess,
  GetMyProfileSuccessPayload,
} from "../../types/auth";

export const getMyProfileRequest = (
): GetMyProfileRequest => ({
  type: myProfileTypes.GET_MY_PROFILE_REQUEST,
});

export const getMyProfileSuccess = (
  payload: GetMyProfileSuccessPayload
): GetMyProfileSuccess => ({
  type: myProfileTypes.GET_MY_PROFILE_SUCCESS,
  payload,
});

export const getMyProfileFailure = (
  payload: GetMyProfileFailurePayload
): GetMyProfileFailure => ({
  type: myProfileTypes.GET_MY_PROFILE_FAILURE,
  payload,
});
