import { aminitiesTypes } from "../../ActionTypes/initialTypes";
import {
  FetchAminitiesFailure,
  FetchAminitiesFailurePayload,
  FetchAminitiesSuccess,
  FetchAminitiesSuccessPayload,
  FetchAminitiesRequest,
} from "../../types/types";

export const fetchAnimitesRequest = (id: string): FetchAminitiesRequest => ({
  type: aminitiesTypes.FETCH_AMINITIES_REQUEST,
  payload: { id: id },
});

export const fetchAnimitesSuccess = (
  payload: FetchAminitiesSuccessPayload
): FetchAminitiesSuccess => ({
  type: aminitiesTypes.FETCH_AMINITIES_SUCCESS,
  payload,
});

export const fetchAnimitesFailure = (
  payload: FetchAminitiesFailurePayload
): FetchAminitiesFailure => ({
  type: aminitiesTypes.FETCH_AMINITIES_FAILURE,
  payload,
});
