import { smLinkTypes } from "../../ActionTypes/initialTypes";
import {
  FetchSmLinkDataFailure,
  FetchSmLinkDataFailurePayload,
  FetchSmLinkDataRequest,
  FetchSmLinkDataSuccess,
  FetchSmLinkDataSuccessPayload,
} from "../../types/types";


export const fetchSmLinkDataRequest = (id: string): FetchSmLinkDataRequest => ({
    type: smLinkTypes.FETCH_SM_LINK_REQUEST,
    payload: { id: id },
  });
  
  export const fetchSmLinkDataSuccess = (
    payload: FetchSmLinkDataSuccessPayload
  ): FetchSmLinkDataSuccess => ({
    type: smLinkTypes.FETCH_SM_LINK_SUCCESS,
    payload,
  });
  
  export const fetchSmLinkDataFailure = (
    payload: FetchSmLinkDataFailurePayload
  ): FetchSmLinkDataFailure => ({
    type: smLinkTypes.FETCH_SM_LINK_FAILURE,
    payload,
  });