import React, { FC, ReactNode } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { CategoryRoom } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import Heading from "shared/Heading/Heading";
import { useIntl } from "react-intl";
//
export interface SectionGridFeaturePlacesProps {
  stayListings?: CategoryRoom[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = (props) => {
  const intl = useIntl();
  const {
      stayListings = [],
      gridClass = "",
      heading = intl.formatMessage({ id: 'Featured-places-to-stay' }),
      subHeading = intl.formatMessage({ id: 'Popular-places-to-stay-that-HMS-recommends-for-you' }),
      headingIsCenter,
      tabs = ["New York", "Tokyo", "Paris", "London"],
    } = props
  
  const renderCard = (stay: CategoryRoom) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <Heading desc={subHeading}>{heading}</Heading>
      {/* <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      /> */}
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {stayListings && stayListings.map((stay) => renderCard(stay))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
