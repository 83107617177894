import { hotelAddOnTypes } from "../../ActionTypes/initialTypes";
import {
  FetchHotelAddOnFailure,
  FetchHotelAddOnFailurePayload,
  FetchHotelAddOnRequest,
  FetchHotelAddOnRequestPayload,
  FetchHotelAddOnSuccess,
  FetchHotelAddOnSuccessPayload,
} from "../../types/types";

export const fetchHotelAddOnRequest = (id: string): FetchHotelAddOnRequest => ({
  type: hotelAddOnTypes.FETCH_HOTEL_ADD_ON_REQUEST,
  payload: { id: id },
});

export const fetchHotelAddOnSuccess = (
  payload: FetchHotelAddOnSuccessPayload
): FetchHotelAddOnSuccess => ({
  type: hotelAddOnTypes.FETCH_HOTEL_ADD_ON_SUCCESS,
  payload,
});

export const fetchHotelAddOnFailure = (
  payload: FetchHotelAddOnFailurePayload
): FetchHotelAddOnFailure => ({
  type: hotelAddOnTypes.FETCH_HOTEL_ADD_ON_FAILURE,
  payload,
});
