export enum loginTypes {
  POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST",
  POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS",
  POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE",
  LOGOUT_REQUEST = "LOGOUT_REQUEST",
}

export enum registerTypes {
  POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST",
  POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS",
  POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE",
}

export enum fpOtpTypes {
  POST_FP_OTP_REQUEST = "POST_FP_OTP_REQUEST",
  POST_FP_OTP_SUCCESS = "POST_FP_OTP_SUCCESS",
  POST_FP_OTP_FAILURE = "POST_FP_OTP_FAILURE",
}

export enum verifyOtpTypes {
  POST_VERIFY_OTP_REQUEST = "POST_VERIFY_OTP_REQUEST",
  POST_VERIFY_OTP_SUCCESS = "POST_VERIFY_OTP_SUCCESS",
  POST_VERIFY_OTP_FAILURE = "POST_VERIFY_OTP_FAILURE",
}

export enum resetPassTypes {
  POST_RESET_PASS_REQUEST = "POST_RESET_PASS_REQUEST",
  POST_RESET_PASS_SUCCESS = "POST_RESET_PASS_SUCCESS",
  POST_RESET_PASS_FAILURE = "POST_RESET_PASS_FAILURE",
}

export enum myProfileTypes {
  GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST",
  GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS",
  GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE",
}
