import React, { useEffect, useState } from "react";
import { RouterProps, useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ArrowRightIcon } from "@heroicons/react/solid";
import CommentListing from "components/CommentListing/CommentListing";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { getHotelReview, postHotelReview } from "api";
import { CircularProgress, Rating } from "@mui/material";
import Joi from "@hapi/joi";
import hasError from "utils/utilFunctions";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useIntl } from "react-intl";

type Params = {
  id: string;
};

const INITIAL_REVIEWDATA = {
  cust_name: "",
  rating: 3,
  review_desc: "",
};

const INITIAL_REVIEWERROR = {
  cust_name: undefined,
  review_desc: undefined,
};

function Reviews() {
  const { id } = useParams<Params>();
  const intl = useIntl();
  const [reviewList, setReviewList] = useState([]);
  const [reviewListArray, setReviewListArray] = useState([]);
  const [reviewData, setReviewData] = useState(INITIAL_REVIEWDATA);
  const [reviewError, setReviewError] = useState(INITIAL_REVIEWERROR);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const getReviewListData = () => {
    getHotelReview(id).then((res) => {
      const reversedArray = res.data.data.reverse();
      setReviewList(reversedArray);
    });
  };

  useEffect(() => {
    getReviewListData();
  }, []);

  useEffect(() => {
    const reviewListData = !showAll ? reviewList.slice(0, 5) : reviewList;
    setReviewListArray(reviewListData);
  }, [showAll, reviewList]);

  const updateReviewData = (key: string, value: string | number) => {
    setReviewData({ ...reviewData, [key]: value });
  };

  const handleSubmit = () => {
    const requiredString = Joi.string().required().trim().messages({
      "string.empty": "This is a mandatory field",
    });

    const errors = {
      cust_name: requiredString.validate(reviewData.cust_name).error?.message,
      review_desc: requiredString.validate(reviewData.review_desc).error
        ?.message,
    };

    setReviewError(errors);
    const hasErr = hasError(errors);

    if (!hasErr) {
      setLoading(true);
      postHotelReview(id, reviewData)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            toast.success("Review Added SuccessFully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setReviewData(INITIAL_REVIEWDATA);
            getReviewListData();
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="listingSection__wrap">
      <h2 className="text-2xl font-semibold">
      {intl.formatMessage({ id: "Reviews" })} ({reviewList.length} {intl.formatMessage({ id: "Reviews" })})
      </h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <div className="space-y-5"  style={{display:"none"}}>
        <Rating
          value={reviewData.rating}
          name="simple-controlled"
          onChange={(event, newValue) => {
            updateReviewData("rating", newValue);
          }}
        />
        <div>
          <Input
            fontClass=""
            sizeClass="h-16 px-4 py-3"
            rounded="rounded-3xl"
            placeholder="Enter Your Name..."
            value={reviewData.cust_name}
            onChange={(e) => updateReviewData("cust_name", e.target.value)}
          />
          {reviewError?.cust_name && (
            <div className="text-red-700 text-sm pl-3">
              {reviewError?.cust_name}
            </div>
          )}
        </div>
        <div>
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
              value={reviewData.review_desc}
              onChange={(e) => updateReviewData("review_desc", e.target.value)}
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
              onClick={() => handleSubmit()}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "white", height: "20px", width: "20px" }}
                  className="w-5 h-5"
                />
              ) : (
                <ArrowRightIcon className="w-5 h-5" />
              )}
            </ButtonCircle>
          </div>
          {reviewError?.review_desc && (
            <div className="text-red-700 text-sm pl-3">
              {reviewError?.review_desc}
            </div>
          )}
        </div>
      </div>

      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        {reviewListArray.map((reviewItem,i) => (
          <CommentListing key={i} data={reviewItem} className="py-8" />
        ))}
      </div>
      <hr />
     {reviewListArray?.length ? <div className="pt-8 pb-8">
        <ButtonPrimary className="rounded-none" onClick={() => setShowAll(!showAll)}>
          {!showAll ? `${intl.formatMessage({ id: "View-more-reviews" })}` : `${intl.formatMessage({ id: "View-less-reviews" })}`}
        </ButtonPrimary>
      </div>: <></>}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        // theme={}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Reviews;
