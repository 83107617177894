/**
 * Base url to make the api url commonly
 */
 const domainHTTPS = `https://`;
 const hostName = window.location.hostname;
 const isDev = hostName === "abc"

 const base =  isDev ? process.env.REACT_APP_DEV_BASE:  process.env.REACT_APP_DEV_BASE

 const baseUrl = domainHTTPS + base;

 export { baseUrl }
