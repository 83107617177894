import rightImg from "images/about-hero-right.png";
import React, { FC, useEffect, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { Ar } from "types/initial";
import { useIntl } from "react-intl";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const intl = useIntl();
  const {
    pending: uiDataPending,
    ui,
    error: uiDataError,
  } = useSelector((state: RootState) => {
    return state.ui;
  });

  const {
    language,
  } = useSelector((state: RootState) => {
    return state.language;
  });

  const [uiData, setUiData] = useState<Ar>();
  
  useEffect(() => {
    ui && setUiData(language === 'en' ? ui.en : ui.ar);
  }, [ui, language]);

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Hotel Management System</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {uiData && <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28 abouts-main">
        <SectionHero
          rightImg={uiData.about_us_bg_img}
          heading={`👋 ${intl.formatMessage({ id: "About-Us." })}`}
          btnText=""
          subHeading={uiData.about_us_descp}
        />

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionSubscribe2 /> */}
      </div>}
    </div>
  );
};

export default PageAbout;
