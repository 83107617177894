import axios from "axios";
import ModalSelectDate from "components/ModalSelectDate";
import StartRating from "components/StartRating/StartRating";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectRoom from "components/ModalSelectRoom";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { RootState } from "redux/reducers/rootReducer";
import { useSelector } from "react-redux";
// import "../../maysor-api-auth"
export interface PayPageProps {
  className?: string;
}
// @ts-nocheck
/* eslint-disable */
const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const intl = useIntl();
  const { language } = useSelector((state: RootState) => {
    return state.language;
  });
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("roomItem"))) {
      (window as any).Moyasar.init({
        element: ".mysr-form",

        amount: JSON.parse(localStorage.getItem("roomItem")).finalAmount * 100,

        currency: "SAR",

        description: JSON.parse(localStorage.getItem("roomItem")).catItemData
          .descp,

        publishable_api_key: "pk_test_jYdnEJ8m5WZm49qvYLZ776bUEX2UgBAWvXuuyiTy",

        callback_url: `https://${window.location.host}/pay-success`, //change env file url
        supported_networks: ["mada", "visa", "mastercard", "amex"],

        methods: ["creditcard", "stcpay"],
        // apple_pay: {
        //   country: "SA",
        //   label: "Ragmna",
        //   validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
        // },

        fixed_width: false,
        on_initiating: function () {
          return new Promise(function (resolve, reject) {
            resolve({});
          });
        },
        on_completed: (payment) =>
          new Promise(async (resolve, reject) => {
            let _json = await JSON.parse(JSON.stringify(payment));

            let _token = localStorage.getItem("auth").replace(/\"/g, "");

            try {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("token", _token.toString());

              let _reqbody = JSON.stringify({
                transId: _json["id"],
                amount: parseFloat(_json["amount"]) / 100,
                statusCd: _json["status"],
                respmsg: _json["status"],
                respData: JSON.stringify(_json).toString(),
                pgid: 1,
                bookingNo: localStorage.getItem("bookingno"),
              });
              let requestOptions: any = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: _reqbody,
              };

              // resolve(true);
              //4111111111111111
              const req = await fetch(
                "https://dev-hotel-api.herokuapp.com/api/hotel/hotel-pay-init",
                requestOptions
              );
              const _result = await req.json();

              if (_result && _result["status"]) {
                resolve(true);
                console.log("successs");
              } else {
                reject();
              }
            } catch (error) {
              resolve(false);
            }
          }),
        on_failure: function (error: any) {
          console.log("err", error);
          // Handle error
        },
      });
    }
  }, []);

  const addServiceAmount = (addOnService) => {
    let addServiceCharge = 0;
    if (addOnService.length) {
      addOnService.forEach((addOnServiceItem) => {
        addServiceCharge = addServiceCharge + addOnServiceItem.total;
      });
    }

    return addServiceCharge;
  };

  const renderSidebar = () => {
    const roomItem = JSON.parse(localStorage.getItem("roomItem"));
    const selectedDate = roomItem?.selectedDate;
    const totalValue = roomItem?.totalAmount;
    const serviceCharge = roomItem?.serviceCharge;
    const roomValue = roomItem?.roomValue;
    const priceValue = roomItem?.priceValue;
    const addOnService = roomItem?.addOnService;
    const addOnServiceAmount = addServiceAmount(roomItem?.addOnService);

    return (
      <>
        {roomItem ? (
          <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            <div className="flex flex-col sm:flex-row sm:items-center flex-wrap">
              <div className="flex-shrink-0 w-full sm:w-100">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <NcImage src={roomItem?.catItemData?.catImages[0].img_url} />
                </div>
              </div>
              <div className="py-5 sm:px-5 space-y-3 standard-title-main ">
                <div>
                  <span className="font-bold mt-1 block text-2xl text-primary-500">
                    {language === "ar"
                      ? roomItem?.catItemData?.title_ar
                      : roomItem?.catItemData?.title}
                  </span>
                  <span className="font-base text-neutral-500 dark:text-neutral-400">
                    {language === "ar"
                      ? roomItem?.catItemData?.descp_ar
                      : roomItem?.catItemData?.descp}
                  </span>
                </div>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 width-new"></div>
              </div>
            </div>

            <div className="margin-0">
              <div className="border-top">
                <h3 className="text-xl font-semibold">
                  {intl.formatMessage({ id: "Your-trip" })}
                </h3>
                <NcModal
                  renderTrigger={(openModal) => (
                    <span
                      onClick={() => openModal()}
                      className="block lg:hidden underline"
                    >
                      {intl.formatMessage({ id: "View-booking-details" })}
                    </span>
                  )}
                  renderContent={renderSidebar}
                  modalTitle="Booking details"
                />
              </div>
              <div className="mt-6 border border-neutral-200 dark:border-neutral-700  flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 "
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">
                      {intl.formatMessage({ id: "Date" })}
                    </span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString({
                        startDate: moment(selectedDate?.startDate),
                        endDate: moment(selectedDate?.endDate),
                      })}
                    </span>
                  </div>
                </button>
                {/* <ModalSelectDate
                defaultValue={selectedDate}
                onSelectDate={() => {}}
                renderChildren={({ openModal }) => (
                  <button
                    // onClick={openModal}
                    className="text-left flex-1 p-5 flex justify-between space-x-5 "
                    type="button"
                  >
                    <div className="flex flex-col">
                      <span className="text-sm text-neutral-400">
                        {intl.formatMessage({ id: "Date" })}
                      </span>
                      <span className="mt-1.5 text-lg font-semibold">
                        {converSelectedDateToString({
                          startDate: moment(selectedDate?.startDate),
                          endDate: moment(selectedDate?.endDate),
                        })}
                      </span>
                    </div>
                  </button>
                )}
              /> */}
                <ModalSelectRoom
                  // defaultValue={guests}
                  roomValue={roomValue}
                  setRoomValue={() => {}}
                  // onChangeGuests={setGuests}
                  renderChildren={({ openModal }) => (
                    <button
                      type="button"
                      // onClick={openModal}
                      className="text-left flex-1 p-5 flex justify-between space-x-5"
                    >
                      <div className="flex flex-col">
                        <span className="text-sm text-neutral-400">
                          {intl.formatMessage({ id: "Rooms" })}
                        </span>
                        <span className="mt-1.5 text-lg font-semibold">
                          <span className="line-clamp-1">
                            {`${roomValue} ${intl.formatMessage({
                              id: "Rooms",
                            })}`}
                          </span>
                        </span>
                      </div>
                      {/* <PencilAltIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                    </button>
                  )}
                />
              </div>
            </div>
            <div className="flex" style={{ marginTop: "5px" }}>
              <div className="w-1/2 p-2">
                <div className="font-medium">
                  {intl.formatMessage({ id: "Check-in" })}
                </div>
                <div className="text-sm font-bold">{config?.check_in}</div>
              </div>
              <div className="w-1/2 p-2">
                <div className="font-medium">
                  {intl.formatMessage({ id: "Check-out" })}
                </div>
                <div className="text-sm font-bold">{config?.check_out}</div>
              </div>
            </div>
            <div
              className="w-full border-b border-neutral-200 dark:border-neutral-700"
              style={{ marginTop: "5px" }}
            ></div>

            <div className="flex flex-col space-y-2">
              <h3 className="text-xl font-semibold flex justify-between">
                <div> {intl.formatMessage({ id: "Price-detail" })}</div>
                <div>
                  {selectedDate?.startDate && selectedDate?.endDate
                    ? moment(selectedDate?.endDate).diff(
                        moment(selectedDate?.startDate),
                        "days"
                      )
                    : 0}{" "}
                  {intl.formatMessage({ id: "night" })}
                </div>
              </h3>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="text-sm">
                  {priceValue} {intl.formatMessage({ id: "SAR" })} x{" "}
                  {selectedDate?.startDate && selectedDate?.endDate
                    ? moment(selectedDate?.endDate).diff(
                        moment(selectedDate?.startDate),
                        "days"
                      )
                    : 0}{" "}
                  {intl.formatMessage({ id: "night" })} x {roomValue}{" "}
                  {intl.formatMessage({ id: "room" })}
                </span>
                <span className="text-sm">
                  {" "}
                  {totalValue} {intl.formatMessage({ id: "SAR" })}{" "}
                </span>
              </div>

              {addOnService && addOnService.length ? (
                <>
                  {" "}
                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 width-new"></div>
                  <h4 className="font-semibold">
                    {intl.formatMessage({ id: "Hotel-Add-On" })}
                  </h4>{" "}
                  {addOnService.map((addOnServiceItem) => {
                    return (
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span className="text-sm">
                          {addOnServiceItem.title}
                        </span>
                        <span className="text-sm">
                          {addOnServiceItem.total}{" "}
                          {intl.formatMessage({ id: "SAR" })}{" "}
                        </span>
                      </div>
                    );
                  })}{" "}
                </>
              ) : (
                <></>
              )}
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="text-sm font-medium">
                  {intl.formatMessage({ id: "Service-charge" })}
                </span>
                <span className="text-sm font-medium">
                  {" "}
                  {serviceCharge} {intl.formatMessage({ id: "SAR" })}{" "}
                </span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>{intl.formatMessage({ id: "Total" })}</span>
                <span>
                  {totalValue + serviceCharge + addOnServiceAmount}{" "}
                  {intl.formatMessage({ id: "SAR" })}{" "}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };
  const roomItem = JSON.parse(localStorage.getItem("roomItem"));
  return (
    <div>
      <div className="container lg:flex" style={{ width: "100%" }}>
        <main
          className="container mt-11 mb-24 lg:w-[65%] lg:mb-32 flex flex-col-reverse lg:flex-row"
          // style={{ width: "65%" }}
        >
          <div className="mysr-form" style={{ width: "100%" }}></div>
        </main>
        <main
          className="container mt-11 mb-24 lg:w-[45%]  lg:mb-32 flex flex-col-reverse lg:flex-row"
          // style={{ width: "45%" }}
        >
          {roomItem && renderSidebar()}
        </main>
      </div>
    </div>
  );
};

export default PayPage;
