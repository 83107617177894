import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { HotelAddOnType } from "redux/types/types";
import Button from "shared/Button/Button";
import Input from "shared/Input/Input";
import { AddOnService } from "types/initial";

export interface HotelAddOnCardProps {
  className?: string;
  hotelAddOnItem: HotelAddOnType;
  addOnService: AddOnService[];
  setAddOnService: React.Dispatch<React.SetStateAction<AddOnService[]>>;
}

const HotelAddOnCard: FC<HotelAddOnCardProps> = ({
  className = "",
  hotelAddOnItem,
  addOnService,
  setAddOnService,
}) => {
  const intl = useIntl();

  const [count, setCount] = useState(1);

  const handleAddToCart = () => {
    const isExist = addOnService.findIndex(
      (ele) => ele.title === hotelAddOnItem.title
    );
    if (isExist >= 0) {
      let latestAddOnService = [...addOnService];
      latestAddOnService[isExist] = {
        ...hotelAddOnItem,
        count: count,
        total: Number(hotelAddOnItem.amount) * count,
      };
      setAddOnService([...latestAddOnService]);
    } else {
      setAddOnService([
        ...addOnService,
        {
          ...hotelAddOnItem,
          count: count,
          total: Number(hotelAddOnItem.amount) * count,
        },
      ]);
    }
  };

  const handleRemoveToCart = () => {
    const isExist = addOnService.findIndex(
      (ele) => ele.title === hotelAddOnItem.title
    );
    if (isExist >= 0) {
      const latestAddOnService = [...addOnService].filter(
        (ele) => ele.title !== hotelAddOnItem.title
      );

      setAddOnService([...latestAddOnService]);
    }
  };
  console.log({ addOnService });
  const isExist =
    addOnService.findIndex((ele) => ele.title === hotelAddOnItem.title) >= 0;
  return (
    <div>
      <div
        className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6  border-2 rounded-2xl border-neutral-200 dark:border-neutral-700 ${className}`}
        data-nc-id="CardCategoryBox1"
      >
        <div className="flex-grow overflow-hidden">
          <h2 className="text-base font-medium">
            <span className="line-clamp-1">{hotelAddOnItem.title}</span>
          </h2>
          <h2 className="text-sm text-neutral-500 dark:text-neutral-400">
            <span className="line-clamp-1">{hotelAddOnItem.descp}</span>
          </h2>
          <div className="lg:flex items-center justify-between my-2 ml-0">
            <div className="flex items-center">
              <div
                className={`block mt-1 mr-2 text-sm font-semibold  text-neutral-500 dark:text-neutral-400`}
              >
               {hotelAddOnItem.amount} {intl.formatMessage({ id: "SAR" })} 
              </div>
              <Input
                type="number"
                className="mt-1 w-[20%] h-[30px] rounded-none p-[7px] mx-2"
                style={{
                  padding: "7px",
                }}
                value={count}
                onChange={(e) =>
                  !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) > 0 &&
                  setCount(Number(e.target.value))
                }
              />
            </div>
            <div className="lg:w-[61%] flex justify-end">
              <button
                className="add-service px-4 py-2 focus:rign-0 flex-end rounded-none text-sm disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
                onClick={() => handleAddToCart()}
              >
                <span className="mx-2 font-bold pt-1">
                  {isExist ? `${intl.formatMessage({ id: "Update" })}` : `${intl.formatMessage({ id: "Add" })}`} {" "}
                  {Number(hotelAddOnItem.amount) * count} {" "} {intl.formatMessage({ id: "SAR" })} 
                </span>
              </button>
              {isExist ? (
                <button
                  className="add-service mx-2 px-4 py-2 focus:rign-0 flex-end rounded-none text-sm disabled:bg-opacity-70 bg-red-700 hover:bg-red-800 text-neutral-50"
                  onClick={() => handleRemoveToCart()}
                >
                  <span className="mx-1 font-bold pt-1">
                  
                  {intl.formatMessage({ id: "Remove" })}
                  </span>
                  
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelAddOnCard;
