import { codeTypes } from "../../ActionTypes/initialTypes";
import { CodeActions, CodeState } from "../../types/types";

const initialState: CodeState = {
  code: "",
};

export default (state = initialState, action: CodeActions) => {
  switch (action.type) {
    case codeTypes.FETCH_CODE_REQUEST:
      return {
        ...state,
        code: action.payload.code,
      };

    case codeTypes.FETCH_CODE_SUCCESS:
      return {
        ...state,
        code: action.payload.code,
      };
    default:
      return {
        ...state,
      };
  }
};
