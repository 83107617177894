import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";

type Props = {
  getPriceValue: () => number;
  selectedDate: DateRage;
  setSelectedDate:React.Dispatch<React.SetStateAction<DateRage>>;
  isDayDisabled?:(date: moment.Moment) => boolean
  isOutSideRange?:(date: moment.Moment) => boolean
  renderDay?: (date: moment.Moment) => JSX.Element
  handleReserve?:()=> void
}

const MobileFooterSticky = (props: Props) => {
  const { getPriceValue , selectedDate, setSelectedDate, isDayDisabled, isOutSideRange,renderDay, handleReserve} = props;
  const intl = useIntl();
  // const [selectedDate, setSelectedDate] = useState<DateRage>({
  //   startDate: moment().add(4, "days"),
  //   endDate: moment().add(10, "days"),
  // });
  const [guestsState, setGuestsState] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
           {getPriceValue()} {intl.formatMessage({ id: "SAR" })}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /{intl.formatMessage({ id: "night" })}
            </span>
          </span>
          <ModalSelectDate
            defaultValue={selectedDate}
            onSelectDate={setSelectedDate}
            isDayDisabled={isDayDisabled}
              // isOutSideRange={(day) => isOutSideRange(day)}
              renderDay={renderDay}
            renderChildren={({ defaultValue, openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString(selectedDate)}
              </span>
            )}
          />
        </div>
        {/* <ModalReserveMobile
          defaultGuests={guestsState}
          defaultDate={selectedDate}
          onChangeDate={setSelectedDate}
          onChangeGuests={setGuestsState}
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={openModal}
            >
              Reserve
            </ButtonPrimary>
          )} */}
          <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={()=> handleReserve()}
            >
              {intl.formatMessage({ id: "Reserve" })}
            </ButtonPrimary>
        
      </div>
    </div>
  );
};

export default MobileFooterSticky;
