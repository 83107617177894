import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useIntl } from "react-intl";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Book & relax",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Smart checklist",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Save more",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const intl = useIntl();
  const { config } = useSelector((state: RootState) => {
    return state.config;
  });

  const { language } = useSelector((state: RootState) => {
    return state.language;
  });

  const {
    pending: aminitiesPending,
    aminities,
    error: aminitiesError,
  } = useSelector((state: RootState) => state.aminities);

  return (
    <>
      <div
        className={`nc-SectionHowItWork  ${className}`}
        data-nc-id="SectionHowItWork"
      >
        <Heading isCenter desc="">
          {intl.formatMessage({ id: "Amenities" })}
        </Heading>

        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 py-5 amenities-main`}
        >
          {aminities?.map((ele,i) => {
            return (
              <div className="text-center"  key={i}>
                <div
                  className="font-amenities"
                  dangerouslySetInnerHTML={{ __html: ele.fonticon }}
                ></div>
                <div>{language === "ar" ? ele.ar_title : ele.title}</div>
              </div>
            );
          })}
        </div>

        <div className="business-main mt-10">
          <div className="py-6">
            <h3 className="text-xl font-semibold">
              {intl.formatMessage({ id: "Business-Description" })}
            </h3>
            {config && config.biz_desc}
          </div>
          <hr />
          <div className="py-6">
            <h3 className="text-xl font-semibold">
              {intl.formatMessage({ id: "Check-in-policy" })}
            </h3>
            {config && config.check_in_policy}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHowItWork;

{
  /* <div className="mt-20 relative grid md:grid-cols-3 gap-20">
          
          <div className="relative flex flex-col items-center max-w-xs mx-auto">
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">Business Description</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {config && config.biz_desc}
              </span>
            </div>
          </div>
          <div className="relative flex flex-col items-center max-w-xs mx-auto">
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">Check-in Policy </h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {config && config.check_in_policy}
              </span>
            </div>
          </div>
          <div className="relative flex flex-col items-center max-w-xs mx-auto">
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">Save more</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {config && config.terms_cond}
              </span>
            </div>
          </div>
        </div> */
}
