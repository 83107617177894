import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useEffect, useState } from "react";
import { Ar, ConfigData, SmLink } from "types/initial";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import LoginButton from "components/Header/LoginButton";
import LangDropdown from "components/Header/LangDropdown";
import PhoneIcon from "@mui/icons-material/Phone";
import { useIntl } from "react-intl";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
      { href: "#", label: "Prototyping" },
      { href: "#", label: "Design systems" },
      { href: "#", label: "Pricing" },
      { href: "#", label: "Security" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Best practices" },
      { href: "#", label: "Support" },
      { href: "#", label: "Developers" },
      { href: "#", label: "Learn design" },
      { href: "#", label: "Releases" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
      { href: "#", label: "Code of Conduct" },
      { href: "#", label: "Community Resources" },
      { href: "#", label: "Contributing" },
      { href: "#", label: "Concurrent Mode" },
    ],
  },
];

const Footer: React.FC = () => {
  const intl = useIntl();
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const [configData, setConfigData] = useState<ConfigData>();

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  const {
    pending: smLinkDataPending,
    smLink,
    error: smLinkDataError,
  } = useSelector((state: RootState) => {
    return state.smLink;
  });

  const {
    pending: uiDataPending,
    ui,
    error: uiDataError,
  } = useSelector((state: RootState) => {
    return state.ui;
  });

  const { language } = useSelector((state: RootState) => {
    return state.language;
  });

  const [smLinkData, setSmLinkData] = useState<SmLink[]>([]);

  const [uiData, setUiData] = useState<Ar>();

  useEffect(() => {
    ui && setUiData(language === "en" ? ui.en : ui.ar);
  }, [ui, language]);

  useEffect(() => {
    smLink && setSmLinkData(smLink);
  }, [smLink]);

  const isLogin = localStorage.getItem("auth");

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-2 lg:py-4 border-t border-neutral-200 dark:border-neutral-700">
      {/* <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 "> */}
      {/* <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col"> */}

      {/* <div className="col-span-2 flex items-center md:col-span-3">
        {smLinkData && <SocialsList1 className="" smLink={smLinkData} />}
      </div> */}
      {/* </div> */}
      {/* <div>{uiData && uiData.footer_text}</div> */}
      {/* {widgetMenus.map(renderWidgetMenuItem)} */}
      {/* </div> */}

      <div className="container py-2">
        <div className="grid grid-cols-2">
          <div className="">
            <div className="flex items-center">
              <img
                src={configData?.biz_logo_url}
                height={"60px"}
                width={"60px"}
              />
              <div className="pl-4">
                <div className="font-bold font-sm text-primary-700">
                  {intl.formatMessage({ id: "Customer-Service" })}
                </div>
                <div className="font-semibold">
                  <PhoneIcon /> {configData?.notify_whatsapp_contact}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="text-center"></div>
      </div>
      <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
      <div className="container">
        <div className="flex items-center grid grid-cols-3 p-2 mt-2">
          {/* <div>{uiData && uiData.footer_text}</div> */}
          <div></div>
          <div className="flex items-center justify-center">
            {!isLogin && (
              <div className="w-fit">
                <LoginButton />
              </div>
            )}
            <div className="w-fit">
              <LangDropdown />
            </div>
          </div>
          <div className="flex-end">
            {/* {smLinkData && <SocialsList1 className="" smLink={smLinkData} />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
