import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  AnnotationIcon,
  HeartIcon,
  HomeIcon,
  LogoutIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { logoutRequest } from "redux/actions/auth/login";
import Avatar from "shared/Avatar/Avatar";

const solutions = [
  {
    name: "Account",
    href: "##",
    icon: UserCircleIcon,
  },
  {
    name: "Messages",
    href: "##",
    icon: AnnotationIcon,
  },
  {
    name: "Wishlists",
    href: "##",
    icon: HeartIcon,
  },
  {
    name: "Booking",
    href: "##",
    icon: HomeIcon,
  },
];

const solutionsFoot = [
  {
    name: "Help",
    href: "##",
    icon: SupportIcon,
  },

  {
    name: "Logout",
    href: "##",
    icon: LogoutIcon,
  },
];

export default function AvatarDropdown() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onLogout = () =>{
    dispatch(logoutRequest())
    localStorage.removeItem('auth')
    // localStorage.removeItem('auth')
  }

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                  <a
                        
                        href={'/account'}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <UserCircleIcon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{intl.formatMessage({ id: "Account-infomation" })}</p>
                        </div>
                      </a>
                      <a
                        
                        href={'/account-booking-history'}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <HomeIcon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{intl.formatMessage({ id: `Booking-History` })}</p>
                        </div>
                      </a>
                      <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                      <a
                        onClick={() => onLogout()}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 hover:cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <LogoutIcon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">
                            {intl.formatMessage({ id: "Logout" })}
                          </p>
                        </div>
                      </a>
                  </div>
                 
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
