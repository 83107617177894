import { uiDataTypes } from "../../ActionTypes/initialTypes";
import {
  FetchUiDataFailure,
  FetchUiDataFailurePayload,
  FetchUiDataRequest,
  FetchUiDataSuccess,
  FetchUiDataSuccessPayload,
} from "../../types/types";


export const fetchUiDataRequest = (id: string): FetchUiDataRequest => ({
    type: uiDataTypes.FETCH_UI_DATA_REQUEST,
    payload: { id: id },
  });
  
  export const fetchUiDataSuccess = (
    payload: FetchUiDataSuccessPayload
  ): FetchUiDataSuccess => ({
    type: uiDataTypes.FETCH_UI_DATA_SUCCESS,
    payload,
  });
  
  export const fetchUiDataFailure = (
    payload: FetchUiDataFailurePayload
  ): FetchUiDataFailure => ({
    type: uiDataTypes.FETCH_UI_DATA_FAILURE,
    payload,
  });