import { hotelAddOnTypes } from "../../ActionTypes/initialTypes";
import {
  HotelAddOnActions, HotelAddOnState
} from "../../types/types";

const initialState: HotelAddOnState  = {
  pending: false,
  hotelAddOn: null,
  error: null,
};

export default (
  state = initialState,
  action: HotelAddOnActions
) => {
  switch (action.type) {
    case hotelAddOnTypes.FETCH_HOTEL_ADD_ON_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case hotelAddOnTypes.FETCH_HOTEL_ADD_ON_SUCCESS:
      return {
        ...state,
        pending: false,
        hotelAddOn: action.payload.hotelAddOn,
        error: null,
      };
    case hotelAddOnTypes.FETCH_HOTEL_ADD_ON_FAILURE:
      return {
        ...state,
        pending: false,
        hotelAddOn: null,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};
