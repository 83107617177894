import MyRouter from "routers/index";
import store from "./redux/saga";
import { Provider } from "react-redux";
import { LanguageProvider } from "containers/LanguageSelector/LanguageSelector";

import enTranslationMessages from './translations/en.json';
import arTranslationMessages from './translations/ar.json';

function App() {
  return (
    <div className="bg-white text-base pb-[70px] md:pb-0 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Provider store={store}>
        <LanguageProvider messages={{en: enTranslationMessages, ar: arTranslationMessages}} >
        <MyRouter />
        </LanguageProvider>
      </Provider>
    </div>
  );
}

export default App;
