import { configTypes } from "../../ActionTypes/initialTypes";
import {
  FetchConfigFailure,
  FetchConfigFailurePayload,
  FetchConfigRequest,
  FetchConfigSuccess,
  FetchConfigSuccessPayload,
} from "../../types/types";

export const fetchConfigRequest = (id: string): FetchConfigRequest => ({
  type: configTypes.FETCH_CONFIG_REQUEST,
  payload: { id: id },
});

export const fetchConfigSuccess = (
  payload: FetchConfigSuccessPayload
): FetchConfigSuccess => ({
  type: configTypes.FETCH_CONFIG_SUCCESS,
  payload,
});

export const fetchConfigFailure = (
  payload: FetchConfigFailurePayload
): FetchConfigFailure => ({
  type: configTypes.FETCH_CONFIG_FAILURE,
  payload,
});
