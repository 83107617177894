import { configTypes } from "../../ActionTypes/initialTypes";
import {
  ConfigActions,
  ConfigState,
} from "../../types/types";

const initialState: ConfigState  = {
  pending: false,
  config: null,
  error: null,
};

export default (
  state = initialState,
  action: ConfigActions
) => {
  switch (action.type) {
    case configTypes.FETCH_CONFIG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case configTypes.FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        pending: false,
        config: action.payload.config,
        error: null,
      };
    case configTypes.FETCH_CONFIG_FAILURE:
      return {
        ...state,
        pending: false,
        config: null,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};
