import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import ModalSelectRoom from "components/ModalSelectRoom";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { useParams } from "react-router-dom";
import { AddOnService, CategoryData, ConfigData } from "types/initial";
import { RouterProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Joi from "@hapi/joi";
import hasError from "utils/utilFunctions";
import { hotelBookingProcess, loginUser, registerUser } from "api";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIntl } from "react-intl";
import { fetchHotelAddOnRequest } from "redux/actions/initial/getHotelAddOnActions";
import Button from "shared/Button/Button";
import HotelAddOnCard from "./HotelAddOnCard";
import { HotelAddOnType } from "redux/types/types";

export interface CheckOutPageProps {
  className?: string;
  history: RouterProps["history"];
}

type PayItem = {
  firstname: string;
  lastname: string;
  email: string;
  contactno: string;
  address1: string;
  address2: string;
  notes: string;
  password: string;
};

type PayItemError = {
  firstname?: string;
  lastname?: string;
  email?: string;
  contactno?: string;
  address1?: string;
  address2?: string;
  notes?: string;
  password?: string;
};

const INITIAL_PAYITEM = {
  firstname: "",
  lastname: "",
  email: "",
  contactno: "",
  address1: "",
  address2: "",
  notes: "",
  password: "",
};

const INITIAL_PAYITEM_ERROR = {
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  contactno: undefined,
  address1: undefined,
  address2: undefined,
  notes: undefined,
  password: undefined,
};

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "", history }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [payItem, setPayItem] = useState<PayItem>(INITIAL_PAYITEM);
  const [isLoading, setLoading] = useState(false);
  const [payItemErrors, setPayItemErrors] = useState<PayItemError>(
    INITIAL_PAYITEM_ERROR
  );
  const { language } = useSelector((state: RootState) => {
    return state.language;
  });
  const {
    pending: configPending,
    config,
    error: configError,
  } = useSelector((state: RootState) => state.config);

  const {
    pending: hotelAddOnPending,
    hotelAddOn,
    error: hotelAddOnError,
  } = useSelector((state: RootState) => state.hotelAddOn);

  const { code } = useSelector((state: RootState) => state.code);

  const [configData, setConfigData] = useState<ConfigData>();

  const [addOnService, setAddOnService] = useState<AddOnService[]>([]);

  const {
    pending: catDataPending,
    category,
    error: catDataError,
  } = useSelector((state: RootState) => {
    return state.category;
  });

  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });
  const [roomValue, setRoomValue] = useState<number>(1);
  const [guests, setGuests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const [catItemData, setCatItemData] = useState<CategoryData>();
  const [isOpenAcc, setIsOpenAcc] = useState(false);

  useEffect(() => {
    const payItemData =
      localStorage.getItem("roomItem") &&
      localStorage.getItem("roomItem") !== null
        ? JSON.parse(localStorage.getItem("roomItem") as string)
        : undefined;
    if (category && payItemData?.catItemData) {
      const categoryItem = category.find(
        (catItem) => catItem.id === payItemData?.catItemData.id
      );
      setCatItemData(categoryItem);
    }
  }, [category]);

  useEffect(() => {
    config && setConfigData(config);
  }, [config]);

  useEffect(() => {
    const payItemData =
      localStorage.getItem("roomItem") &&
      localStorage.getItem("roomItem") !== null
        ? JSON.parse(localStorage.getItem("roomItem") as string)
        : undefined;
    if (payItemData && payItemData?.catItemData) {
      setRoomValue(payItemData.roomValue);
      setSelectedDate({
        startDate: moment(payItemData.selectedDate.startDate),
        endDate: moment(payItemData.selectedDate.endDate),
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchHotelAddOnRequest(code));
  }, []);

  const getPrice = (val: string) => {
    return val === "0.00" ? catItemData?.avg_price_per_day : val;
  };

  const returnValueBasedOnDay = (day: string) => {
    if (day === "Sunday") {
      return getPrice(catItemData?.price_for_sunday);
    } else if (day === "Monday") {
      return getPrice(catItemData?.price_for_monday);
    } else if (day === "Tuesday") {
      return getPrice(catItemData?.price_for_tuesday);
    } else if (day === "Wednesday") {
      return getPrice(catItemData?.price_for_wed);
    } else if (day === "Thursday") {
      return getPrice(catItemData?.price_for_thursday);
    } else if (day === "Friday") {
      return getPrice(catItemData?.price_for_friday);
    } else if (day === "Saturday") {
      return getPrice(catItemData?.price_for_sat);
    }
  };

  const getPriceValue = () => {
    let price = 0;
    const dayDiff = Number(
      selectedDate.endDate?.diff(selectedDate.startDate, "days")
    );
    for (let i = 0; i < dayDiff; i++) {
      const dayPrice = returnValueBasedOnDay(
        moment(moment(selectedDate?.startDate).add(i, "days"))?.format("dddd")
      );
      price = price + Number(dayPrice);
    }
    return Number((price / dayDiff).toFixed(2));
  };

  const addServiceAmount = () => {
    let addServiceCharge = 0;
    if (addOnService.length) {
      addOnService.forEach((addOnServiceItem) => {
        addServiceCharge = addServiceCharge + addOnServiceItem.total;
      });
    }

    return addServiceCharge;
  };

  const getTotalValue = useCallback(() => {
    return (
      getPriceValue() *
      (selectedDate.endDate !== null && selectedDate.startDate !== null
        ? Number(selectedDate.endDate?.diff(selectedDate.startDate, "days"))
        : 0) *
      roomValue
    );
  }, [addOnService, roomValue, selectedDate]);

  const totalValue = getTotalValue();

  const serviceCharge = configData
    ? Number(
        (
          (Number(configData.tax_percentage) *
            (totalValue + addServiceAmount())) /
          100
        ).toFixed(2)
      )
    : 0;

  const finalPayValue = totalValue + addServiceAmount() + serviceCharge;

  function updateUser(inputKey: keyof PayItem, value: string) {
    setPayItem({
      ...payItem,
      [inputKey]: value,
    });
  }

  function hotelAddOnServiceArrayForPayload() {
    if (addOnService && addOnService.length) {
      const returnArray = addOnService.map((serviceItem) => ({
        item: serviceItem.title,
        qty: serviceItem.count,
        amount: Number(serviceItem.amount),
        total_amount: serviceItem.total,
      }));
      return returnArray;
    }
    return [];
  }

  function callBookingAPI(code) {
    setLoading(true);
    const format = "YYYY-MM-DD";
    const payDetailArray = [
      {
        item: `سعر الاقامة`,
        qty: roomValue,
        amount: getPriceValue(),
        total_amount: totalValue,
      },
      // {
      //   item: `${intl.formatMessage({ id: "Service-charge" })}`,
      //   qty: 1,
      //   amount: serviceCharge,
      //   total_amount: serviceCharge,
      // },
      ...hotelAddOnServiceArrayForPayload(),
    ];
    const payload = {
      code: code,
      ...payItem,
      endDate: moment(selectedDate.endDate).format(format),
      startDate: moment(selectedDate.startDate).format(format),
      reqRoom: roomValue,
      total_amount: finalPayValue,
      tax_amount: serviceCharge,
      catId: catItemData.id,
      detail: payDetailArray,
    };
    const payItemData = {
      roomValue: roomValue,
      selectedDate: selectedDate,
      catItemData: catItemData,
      finalAmount: finalPayValue,
      totalAmount: totalValue,
      serviceCharge: serviceCharge,
      priceValue: getPriceValue(),
      addOnService: addOnService,
    };
    localStorage.setItem("roomItem", JSON.stringify(payItemData));
    hotelBookingProcess(payload)
      .then((res) => {
        if (res.data.status == false) {
          if (res.data.data === "Invalid token or token is expired") {
            loginUserHere(code);
          } else {
            toast.error(res.data.data, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          localStorage.setItem("bookingno", res.data.data["bookingNo"]);
          toast.success("Booking confirmed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push(`/pay-done`);
        }
        setLoading(false);
      })
      .catch((er) => console.log(er));
  }

  function loginUserHere(code) {
    setLoading(true);
    const _code = localStorage.getItem("code");
    const loginPayload = {
      email: payItem.email,
      password: payItem.password,
      code: _code,
    };
    loginUser(loginPayload)
      .then((res) => {
        if (res.data.status == false) {
          toast.error(res.data.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        } else {
          localStorage.setItem("auth", res.data.data);
          callBookingAPI(code);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handleSubmit() {
    const requiredString = Joi.string().required().trim().messages({
      "string.empty": "This is a mandatory field",
    });

    const emailString = requiredString.email({ tlds: false }).messages({
      "string.email": "Please Enter Valid Email",
    });

    const errors = {
      firstname: requiredString.validate(payItem.firstname).error?.message,
      lastname: requiredString.validate(payItem.lastname).error?.message,
      email: emailString.validate(payItem.email).error?.message,
      password: requiredString.validate(payItem.password).error?.message,
      contactno: requiredString.validate(payItem.contactno).error?.message,
      address1: undefined,
      address2: undefined,
      notes: undefined,
    };

    setPayItemErrors(errors);
    const hasErr = hasError(errors);
    const code = localStorage.getItem("code");

    if (!hasErr && code) {
      // const isLogin = localStorage.getItem("auth");
      // if (isLogin) {
      //   callBookingAPI(code);
      // } else {

      setLoading(true);
      const payload = {
        firstname: payItem.firstname,
        lastname: payItem.lastname,
        email: payItem.email,
        password: payItem.password,
        contactno: payItem.contactno,
        code: code,
      };
      const payItemData = {
        roomValue: roomValue,
        selectedDate: selectedDate,
        catItemData: catItemData,
        finalAmount: finalPayValue,
        totalAmount: totalValue,
        serviceCharge: serviceCharge,
        priceValue: getPriceValue(),
      };
      localStorage.setItem("roomItem", JSON.stringify(payItemData));
      registerUser(payload)
        .then((res) => {
          if (res.data.data === "SUCCESS") {
            loginUserHere(code);
          } else if (res.data.data === "User already exists") {
            loginUserHere(code);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // }
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center flex-wrap">
          <div className="flex-shrink-0 w-full sm:w-100">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={catItemData?.catImages[0].img_url} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3 standard-title-main ">
            <div>
              <span className="font-bold mt-1 block text-2xl text-primary-500">
                {language === "ar" ? catItemData?.title_ar : catItemData?.title}
              </span>
              <span className="font-base text-neutral-500 dark:text-neutral-400">
                {language === "ar" ? catItemData?.descp_ar : catItemData?.descp}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 width-new"></div>
          </div>
        </div>

        <div className="margin-0">
          <div className="border-top">
            <h3 className="text-xl font-semibold">
              {intl.formatMessage({ id: "Your-trip" })}
            </h3>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700  flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <ModalSelectDate
              defaultValue={selectedDate}
              onSelectDate={setSelectedDate}
              renderChildren={({ openModal }) => (
                <button
                  // onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 "
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">
                      {intl.formatMessage({ id: "Date" })}
                    </span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString(selectedDate)}
                    </span>
                  </div>
                  {/* <PencilAltIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />

            {/* <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests, ${guests.guestInfants || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilAltIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> */}
            {/* <ModalSelectRoom
              roomValue={roomValue}
              setRoomValue={setRoomValue}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">
                      {intl.formatMessage({ id: "Rooms" })}
                    </span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${roomValue} ${intl.formatMessage({ id: "Rooms" })}`}
                      </span>
                    </span>
                  </div>
                </button>
              )}
            /> */}
          </div>
        </div>

        <div className="flex" style={{ marginTop: "5px" }}>
          <div className="w-1/2 p-2">
            <div className="font-medium">
              {intl.formatMessage({ id: "Check-in" })}
            </div>
            <div className="text-sm font-bold">{config?.check_in}</div>
          </div>
          <div className="w-1/2 p-2">
            <div className="font-medium">
              {intl.formatMessage({ id: "Check-out" })}
            </div>
            <div className="text-sm font-bold">{config?.check_out}</div>
          </div>
        </div>
        <div
          className="w-full border-b border-neutral-200 dark:border-neutral-700"
          style={{ marginTop: "5px" }}
        ></div>
        <div className="flex flex-col space-y-2">
          <h3 className="text-xl font-semibold flex justify-between">
            <div>{intl.formatMessage({ id: "Price-detail" })}</div>
            <div>
              {" "}
              {selectedDate.startDate && selectedDate.endDate
                ? selectedDate.endDate?.diff(selectedDate.startDate, "days")
                : 0}{" "}
              {intl.formatMessage({ id: "night" })}
            </div>
          </h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="text-sm">
              {getPriceValue()} {intl.formatMessage({ id: "SAR" })} x{" "}
              {selectedDate.startDate && selectedDate.endDate
                ? selectedDate.endDate?.diff(selectedDate.startDate, "days")
                : 0}{" "}
              {intl.formatMessage({ id: "night" })} x {roomValue}{" "}
              {intl.formatMessage({ id: "room" })}
            </span>
            <span className="text-sm">
              {" "}
              {totalValue} {intl.formatMessage({ id: "SAR" })}{" "}
            </span>
          </div>

          {addOnService && addOnService.length ? (
            <>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 width-new"></div>
              <h4 className="font-semibold">
                {intl.formatMessage({ id: "Hotel-Add-On" })}
              </h4>
              {addOnService.map((addOnServiceItem) => {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span className="text-sm">
                      {addOnServiceItem.title} x {addOnServiceItem.count}
                    </span>
                    <span className="text-sm">
                      {" "}
                      {addOnServiceItem.total}{" "}
                      {intl.formatMessage({ id: "SAR" })}{" "}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="text-sm font-medium">
              {intl.formatMessage({ id: "Service-charge" })}
            </span>
            <span className="text-sm font-medium">
              {serviceCharge} {intl.formatMessage({ id: "SAR" })}{" "}
            </span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{intl.formatMessage({ id: "Total" })}</span>
            <span>
              {" "}
              {finalPayValue} {intl.formatMessage({ id: "SAR" })}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {intl.formatMessage({ id: "Confirm-and-payment" })}
        </h2>
        <NcModal
          renderTrigger={(openModal) => (
            <span
              onClick={() => openModal()}
              className="block lg:hidden underline"
            >
              {intl.formatMessage({ id: "View-booking-details" })}
            </span>
          )}
          renderContent={renderSidebar}
          modalTitle="Booking details"
        />
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <h3 className="text-2xl font-semibold">
            {intl.formatMessage({ id: "Pay-with" })}
          </h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="max-w-md space-y-6 form-main ">
            {/* <Tab.Group>
              <Tab.List className="flex my-5">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="" />
                      <img className="w-8" src={mastercardPng} alt="" />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group> */}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "FirstName" })}
              </span>
              <Input
                type="text"
                className="mt-1"
                value={payItem.firstname}
                onChange={(e) => updateUser("firstname", e.target.value)}
              />
              {payItemErrors?.firstname && (
                <div className="text-red-700 text-sm">
                  {payItemErrors.firstname}
                </div>
              )}
            </label>
            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "LastName" })}
              </span>
              <Input
                type="text"
                className="mt-1"
                value={payItem.lastname}
                onChange={(e) => updateUser("lastname", e.target.value)}
              />
              {payItemErrors?.lastname && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.lastname}
                </div>
              )}
            </label>
            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Email-address" })}
              </span>
              <Input
                type="email"
                className="mt-1"
                value={payItem.email}
                onChange={(e) => updateUser("email", e.target.value)}
              />
              {payItemErrors?.email && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.email}
                </div>
              )}
            </label>
            <label className="block form-group">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Password" })}
              </span>
              <Input
                type="password"
                className="mt-1"
                value={payItem.password}
                onChange={(e) => updateUser("password", e.target.value)}
              />
              {payItemErrors?.password && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.password}
                </div>
              )}
            </label>
            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Contact-No" })}
              </span>
              <Input
                type="text"
                className="mt-1"
                maxLength={15}
                value={payItem.contactno}
                onChange={(e) =>
                  !isNaN(Number(e.target.value)) &&
                  updateUser("contactno", e.target.value)
                }
              />
              {payItemErrors?.contactno && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.contactno}
                </div>
              )}
            </label>

            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Address-1" })}
              </span>
              <Textarea
                rows={2}
                // type="text"
                className="mt-1"
                value={payItem.address1}
                onChange={(e) => updateUser("address1", e.target.value)}
              />
              {payItemErrors?.address1 && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.address1}
                </div>
              )}
            </label>

            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Address-2" })}
              </span>
              <Textarea
                rows={2}
                // type="text"
                className="mt-1"
                value={payItem.address2}
                onChange={(e) => updateUser("address2", e.target.value)}
              />
              {payItemErrors?.address2 && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.address2}
                </div>
              )}
            </label>

            <label className="block form-group">
              <span className="text-neutral-800 dark:text-neutral-200">
                {intl.formatMessage({ id: "Notes" })}
              </span>
              <Textarea
                rows={2}
                // type="text"
                className="mt-1"
                value={payItem.notes}
                onChange={(e) => updateUser("notes", e.target.value)}
              />
              {payItemErrors?.notes && (
                <div className="text-red-700 text-sm">
                  {payItemErrors?.notes}
                </div>
              )}
            </label>
          </div>

          {hotelAddOn && hotelAddOn.length ? (
            <Accordion
              expanded={isOpenAcc}
              onChange={() => setIsOpenAcc(!isOpenAcc)}
              className="accordian-hotel my-6 bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h4 className="font-bold py-4 block text-2xl text-primary-500">
                  {intl.formatMessage({ id: "Hotel-Add-On" })}
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 gap-5">
                  {hotelAddOn?.map((hotelAddOnItem: HotelAddOnType) => {
                    return (
                      <HotelAddOnCard
                        hotelAddOnItem={hotelAddOnItem}
                        addOnService={addOnService}
                        setAddOnService={setAddOnService}
                      />
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <></>
          )}

          <div className="mt-3">
            <ButtonPrimary loading={isLoading} onClick={() => handleSubmit()}>
              {intl.formatMessage({ id: "Confirm-and-Pay" })}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow" style={{ width: "50%" }}>
          {renderSidebar()}
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default withRouter(CheckOutPage);
