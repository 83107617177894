import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { postLoginFailure, postLoginSuccess } from "../actions/auth/login";

import {
  postRegisterFailure,
  postRegisterSuccess,
} from "../actions/auth/register";

import {
  getMyProfileFailure,
  getMyProfileSuccess,
} from "../actions/auth/myProfile";

import {
  loginTypes,
  myProfileTypes,
  registerTypes,
} from "../ActionTypes/authTypes";
import {
  GetMyProfileRequest,
  PostLoginRequest,
  PostRegisterRequest,
} from "../types/auth";

import { getMyProfile, loginUser, registerUser } from "../../api/index";

function* postLoginSaga(actionData: PostLoginRequest) {
  try {
    const response: AxiosResponse = yield call(loginUser, actionData.payload);

    yield put(
      postLoginSuccess({
        data: response.data.data,
        status: response.data.status,
      })
    );
  } catch (e) {
    yield put(
      postLoginFailure({
        error: e as string,
      })
    );
  }
}

function* postRegisterSaga(actionData: PostRegisterRequest) {
  try {
    const response: AxiosResponse = yield call(
      registerUser,
      actionData.payload
    );
    yield put(
      postRegisterSuccess({
        data: response.data.data,
        status: response.data.status,
      })
    );
  } catch (e) {
    yield put(
      postRegisterFailure({
        error: e as string,
      })
    );
  }
}

function* getMyProfileSaga(actionData: GetMyProfileRequest) {
  try {
    const response: AxiosResponse = yield call(getMyProfile);

    yield put(
      getMyProfileSuccess({
        myProfileInfo: response.data.data,
        status: response.data.status,
      })
    );
  } catch (e) {
    yield put(
      getMyProfileFailure({
        error: e as string,
      })
    );
  }
}

function* configSaga() {
  yield all([takeLatest(loginTypes.POST_LOGIN_REQUEST, postLoginSaga)]);
  yield all([
    takeLatest(registerTypes.POST_REGISTER_REQUEST, postRegisterSaga),
  ]);
  yield all([
    takeLatest(myProfileTypes.GET_MY_PROFILE_REQUEST, getMyProfileSaga),
  ]);
}

export default configSaga;
