import { languageTypes } from "../../ActionTypes/initialTypes";
import {
  LanguageDataActions,
  LanguageDataState,
} from "../../types/types";

const initialState:  LanguageDataState = {
  language: "ar",
};

export default (
  state = initialState,
  action: LanguageDataActions
) => {
  switch (action.type) {

    case languageTypes.FETCH_LANGUAGE_REQUEST:
      return {
        ...state,
        language: action.payload.language,
      };

    case languageTypes.FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.payload.language,
      };
    default:
      return {
        ...state,
      };
  }
};
