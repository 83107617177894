import { smLinkTypes } from "../../ActionTypes/initialTypes";
import { SmLinkDataActions, SmLinkDataState} from "../../types/types";

const initialState: SmLinkDataState = {
  pending: false,
  smLink: null,
  error: null
};

export default (state = initialState, action: SmLinkDataActions) => {
  switch (action.type) {
    case smLinkTypes.FETCH_SM_LINK_REQUEST:
      return {
        ...state,
        pending: true
      };
    case smLinkTypes.FETCH_SM_LINK_SUCCESS:
      return {
        ...state,
        pending: false,
        smLink: action.payload.smLink,
        error: null
      };
    case smLinkTypes.FETCH_SM_LINK_FAILURE:
      return {
        ...state,
        pending: false,
        smLink: null,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
