import { catDataTypes } from "../../ActionTypes/initialTypes";
import {
  FetchCatDataFailure,
  FetchCatDataFailurePayload,
  FetchCatDataRequest,
  FetchCatDataSuccess,
  FetchCatDataSuccessPayload,
} from "../../types/types";


export const fetchCatDataRequest = (id: string): FetchCatDataRequest => ({
    type: catDataTypes.FETCH_CAT_DATA_REQUEST,
    payload: { id: id },
  });
  
  export const fetchCatDataSuccess = (
    payload: FetchCatDataSuccessPayload
  ): FetchCatDataSuccess => ({
    type: catDataTypes.FETCH_CAT_DATA_SUCCESS,
    payload,
  });
  
  export const fetchCatDataFailure = (
    payload: FetchCatDataFailurePayload
  ): FetchCatDataFailure => ({
    type: catDataTypes.FETCH_CAT_DATA_FAILURE,
    payload,
  });