// import { getCodeDomain } from "api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouterProps, useParams, withRouter } from "react-router-dom";
import { fetchCodeRequest } from "redux/actions/initial/getCodeAction";

type Params = {
  id: string;
};

type Props = {
  history: RouterProps["history"];
};

function PageHome(props: Props) {
  let { id } = useParams<Params>();
  const { history } = props;
  console.log(id);
  if (
    !id &&
    localStorage.getItem("code") &&
    localStorage.getItem("code") !== undefined &&
    localStorage.getItem("code") !== ""
  ) {
    id = localStorage.getItem("code");
    history.push(`/hotel/${id}`);
  }

  useEffect(() => {
    // getCodeDomain(window.location.host)
    //   .then((res) => {
    //     if (res.data.status) {
    //       dispatch(fetchCodeRequest(res.data.data));
    //       history.push(`/hotel/${res.data.data}`);
    //     }
    //   })
    //   .catch(() => {
    //     //err
    //   });
  }, []);

  return <div className="nc-PageHome relative overflow-hidden"></div>;
}

export default withRouter(PageHome);
