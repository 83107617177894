import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  fetchConfigFailure,
  fetchConfigSuccess,
} from "../actions/initial/initialActions";

import {
  fetchUiDataFailure,
  fetchUiDataSuccess,
} from "../actions/initial/getUiDataActions";

import {
  fetchCatDataFailure,
  fetchCatDataSuccess,
} from "../actions/initial/getCatDataActions";

import {
  fetchSmLinkDataFailure,
  fetchSmLinkDataSuccess,
} from "../actions/initial/getSmLinkAction";

import {
  fetchAnimitesFailure,
  fetchAnimitesSuccess,
} from "../actions/initial/getAminitesActions";

import {
  fetchHotelAddOnFailure,
  fetchHotelAddOnSuccess,
} from "../actions/initial/getHotelAddOnActions";

import { fetchLanguageSuccess } from "../actions/initial/getLanguageActions";

import {
  configTypes,
  uiDataTypes,
  catDataTypes,
  smLinkTypes,
  languageTypes,
  aminitiesTypes,
  codeTypes,
  hotelAddOnTypes,
} from "../ActionTypes/initialTypes";
import {
  FetchConfigRequest,
  FetchCatDataRequest,
  FetchUiDataRequest,
  FetchSmLinkDataRequest,
  FetchLanguageDataRequest,
  FetchAminitiesRequest,
  FetchCodeRequest,
  FetchHotelAddOnRequest,
} from "../types/types";
import {
  getConfig,
  getUIData,
  getCatData,
  getSmLink,
  getAmenities,
  getHotelAddOn,
} from "../../api/index";
import { fetchCodeSuccess } from "redux/actions/initial/getCodeAction";

function* fetchConfigSaga(actionData: FetchConfigRequest) {
  try {
    const response: AxiosResponse = yield call(
      getConfig,
      actionData.payload.id
    );

    yield put(
      fetchConfigSuccess({
        config: response.data.data[0],
      })
    );
  } catch (e) {
    yield put(
      fetchConfigFailure({
        error: e as string,
      })
    );
  }
}

function* fetchUiDataSaga(actionData: FetchUiDataRequest) {
  try {
    const response: AxiosResponse = yield call(
      getUIData,
      actionData.payload.id
    );
    yield put(
      fetchUiDataSuccess({
        ui: response.data.data,
      })
    );
  } catch (e) {
    yield put(
      fetchUiDataFailure({
        error: e as string,
      })
    );
  }
}

function* fetchCatDataSaga(actionData: FetchCatDataRequest) {
  try {
    const response: AxiosResponse = yield call(
      getCatData,
      actionData.payload.id
    );
    yield put(
      fetchCatDataSuccess({
        category: response.data.data,
      })
    );
  } catch (e) {
    yield put(
      fetchCatDataFailure({
        error: e as string,
      })
    );
  }
}

function* fetchSmLinkDataSaga(actionData: FetchSmLinkDataRequest) {
  try {
    const response: AxiosResponse = yield call(
      getSmLink,
      actionData.payload.id
    );
    yield put(
      fetchSmLinkDataSuccess({
        smLink: response.data.data,
      })
    );
  } catch (e) {
    yield put(
      fetchSmLinkDataFailure({
        error: e as string,
      })
    );
  }
}

function* fetchAminitiesSaga(actionData: FetchAminitiesRequest) {
  try {
    const response: AxiosResponse = yield call(
      getAmenities,
      actionData.payload.id
    );

    yield put(
      fetchAnimitesSuccess({
        aminities: response.data.data,
      })
    );
  } catch (e) {
    yield put(
      fetchAnimitesFailure({
        error: e as string,
      })
    );
  }
}

function* fetchLanguageDataSaga(actionData: FetchLanguageDataRequest) {
  yield put(
    fetchLanguageSuccess({
      language: actionData.payload.language,
    })
  );
}

function* fetchCodeSaga(actionData: FetchCodeRequest) {
  yield put(
    fetchCodeSuccess({
      code: actionData.payload.code,
    })
  );
}

function* fetchHotelAddOnSaga(actionData: FetchHotelAddOnRequest) {
  try {
    const response: AxiosResponse = yield call(
      getHotelAddOn,
      actionData.payload.id
    );

    yield put(
      fetchHotelAddOnSuccess({
        hotelAddOn: response.data.data,
      })
    );
  } catch (e) {
    yield put(
      fetchHotelAddOnFailure({
        error: e as string,
      })
    );
  }
}

function* configSaga() {
  yield all([takeLatest(configTypes.FETCH_CONFIG_REQUEST, fetchConfigSaga)]);
  yield all([takeLatest(uiDataTypes.FETCH_UI_DATA_REQUEST, fetchUiDataSaga)]);
  yield all([
    takeLatest(catDataTypes.FETCH_CAT_DATA_REQUEST, fetchCatDataSaga),
  ]);
  yield all([
    takeLatest(smLinkTypes.FETCH_SM_LINK_REQUEST, fetchSmLinkDataSaga),
  ]);
  yield all([
    takeLatest(aminitiesTypes.FETCH_AMINITIES_REQUEST, fetchAminitiesSaga),
  ]);
  yield all([
    takeLatest(languageTypes.FETCH_LANGUAGE_REQUEST, fetchLanguageDataSaga),
  ]);
  yield all([takeLatest(codeTypes.FETCH_CODE_REQUEST, fetchCodeSaga)]);
  yield all([
    takeLatest(hotelAddOnTypes.FETCH_HOTEL_ADD_ON_REQUEST, fetchHotelAddOnSaga),
  ]);
}

export default configSaga;
